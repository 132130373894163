import { Breadcrumb, BreadcrumbProps } from "antd";
import { FC, ReactNode } from "react";
import { MenuNode } from "src/model/MenuNode";
import { GLOB } from "src/util/Glob";
import './BreadcrumbXM.less';

export const BreadcrumbXM: FC<{ bread?: BreadcrumbProps }> = props => {
  return <Breadcrumb separator="|" {...props.bread}
    className={`xm-breadcrumb xm-breadcrumb-center ${props.bread?.className || ''}`}>
    {MenuNode.getParentNodes(GLOB.selectedItem).map((mn, index, array) => <Breadcrumb.Item key={mn.url || mn.key}>
      {index === array.length - 1 ? <a onClick={e => GLOB.scrollSelectedItemIntoView()}>{mn.title as ReactNode}</a>
        : mn.title as ReactNode}
    </Breadcrumb.Item>)}
  </Breadcrumb>;
};