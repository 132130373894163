import { DeleteOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Tree } from "antd";
import { useContext } from "react";
import { DataNode } from "src/content/monitoring/sg/interface";
import { ItemNode } from "../ServerGroupForm";
import { ServerGroupFormContext } from "../ServerGroupFormContext";

type ExtendedDataNode = DataNode & NodeExtension;

interface NodeExtension {
    previewNew: boolean;
    previewDeleted: boolean;
}

const MakeTreeData = (devices: ItemNode[]) => {
    const result: ExtendedDataNode[] = [];
    const makeTitle = (label: string, hwType: string) => (
        <>
            <span>{label}</span>
        </>
    );

    devices.sort((a, b) => a.label.localeCompare(b.label)).forEach(device => {
        result.push({
            title: makeTitle(device.label, device.hw_type),
            key: device.label,
            isLeaf: true,
            hwType: device.hw_type,
            itemId: device.item_id,
            previewNew: device.previewNew,
            previewDeleted: device.previewDeleted
        });
    });

    return result;
};

const ServerPreview = () => {

    const {
        selectedServers,
        setSelectedServers
    } = useContext(ServerGroupFormContext);

    const treeData = MakeTreeData(selectedServers);

    const handleDelete = (item: ExtendedDataNode, recover: boolean) => {
        setSelectedServers((prev) => {
            return prev.map((it) => {
                if (item.itemId === it.item_id) {
                    return {
                        ...it,
                        previewDeleted: !recover,
                        checked: recover
                    }
                }
                return it;
            })
        })
    }

    return (
        <Card
            className="prewie-form"
            style={{ marginTop: "1.5em", marginBottom: "1.5em" }}
            title={
                <div className="prewie-header">
                    <div className="prewie-header-title">Selection Preview</div>
                </div>
            }
        >
            {selectedServers.length ?
                <Tree
                    treeData={treeData}
                    className={"prewie prewie-form"}
                    selectable={false}
                    height={200} /// must be same as .prewie-form height
                    titleRender={(device: ExtendedDataNode) => {
                        return (
                            <div className="prewie-title">
                                <Button className='ant-btn-link hover-danger' icon={device.previewDeleted ? <UndoOutlined /> : <DeleteOutlined />} onClick={() => handleDelete(device, device.previewDeleted)} />
                                <div>
                                    {device.previewDeleted
                                        ?
                                        <s>{device.title}</s>
                                        :
                                        device.previewNew
                                            ?
                                            <b>{device.title}</b>
                                            :
                                            device.title
                                    }
                                </div>
                            </div>
                        );
                    }}
                /> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="prewie-empty" />
            }
        </Card>
    )
}

export default ServerPreview