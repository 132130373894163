import { BOX_TYPE, CHART_TYPE, EXPORT_METHOD, GRAPH_INTERVAL, METRIC_AGGREGATE, MULTIPLIER, ORIENTATION, TABLE_STYLE_CLASS, TEXT_STYLE_CLASS, UNIT_PREFIX } from '@const/pageResponse.constants';
import { Status } from '@dto/healthStatus.dto';
import { BasicResponseDTO } from './basicResponse.dto';
import { TOPOLOGY_ITEM_TYPE, TOPOLOGY_TYPE } from './constants/pageResponse.constants';
import { DashboardGroup } from './dashboard.dto';
import { Unit } from './subsystemMetricsResponse.dto';
import { PredictionBase } from './timeseriesRequest.dto';

export type ChartType = typeof CHART_TYPE[keyof typeof CHART_TYPE];
export type BoxType = typeof BOX_TYPE[keyof typeof BOX_TYPE];
export type ExportMethod = typeof EXPORT_METHOD[keyof typeof EXPORT_METHOD];
export type GraphInterval = typeof GRAPH_INTERVAL[keyof typeof GRAPH_INTERVAL];
export type MetricAggregate = typeof METRIC_AGGREGATE[keyof typeof METRIC_AGGREGATE];
export type Multiplier = typeof MULTIPLIER[keyof typeof MULTIPLIER];
export type TableStyleClass = typeof TABLE_STYLE_CLASS[keyof typeof TABLE_STYLE_CLASS];
export type TextStyleClass = typeof TEXT_STYLE_CLASS[keyof typeof TEXT_STYLE_CLASS];
export type UnitPrefix = typeof UNIT_PREFIX[keyof typeof UNIT_PREFIX];
export type TopologyType = typeof TOPOLOGY_TYPE[keyof typeof TOPOLOGY_TYPE];
export type TopologyItemType = typeof TOPOLOGY_ITEM_TYPE[keyof typeof TOPOLOGY_ITEM_TYPE];
export type OrientationType = typeof ORIENTATION[keyof typeof ORIENTATION];

export interface IMetricType {
  unit: Unit;
  shortcut: string;
  defaultPrefix?: UnitPrefix;
  defaultPrecision?: number;
  metricType?: string;
  unitLabel?: string;
  minY?: number;
}

export class Metric implements IMetricType {
  type: ChartType;
  stacked: boolean;
  metric: string;
  // negative order value to display below X axis
  order: number;
  aggregate?: MetricAggregate;
  unit: Unit;
  unitLabel?: string;
  shortcut: string;
  label?: string;
  color?: string;
  defaultPrefix?: UnitPrefix;
  defaultPrecision?: number;
  minY?: number;
}

//export class TableColumn {
//  order: number;
//  property?: string;
//  metric?: string;
//  relation?: string;
//}


export class CapacityTableBase {
  header: CapacityTableHeader[];
  rows: CapacityTableColumn[][];
}

export class TableHeaderBase {
  order: number;
  value: string;
  prefix?: UnitPrefix;
  number?: boolean;
  decimal?: number;
}

export class CapacityTableHeader extends TableHeaderBase {
  thermometer?: boolean;
}

export class CapacityTableColumn {
  order: number;
  value: number | string;
  url?: string;
  tooltip?: string;
}

export class CapacityTableBox extends CapacityTableBase implements Box {
  type = BOX_TYPE.CapacityTableBox;
}

export class TableHeader extends TableHeaderBase {
  unit?: Unit;
  multiplier?: 1000 | 1024;
  shortcut?: string;
  styleClass?: TableStyleClass;
  sort?: 'desc' | 'asc';
  noWrap?: boolean;
  trim: boolean;
  group?: string; // deprecated
  newLines?: boolean;
  linuxMpath?: boolean;
  trimLength?: number;
  disable?: {
    search?: boolean;
    sort?: boolean;
  }
}

export class TableGroupHeader {
  order: number;
  value: string;
  group: string;
  number?: boolean;
}

export class TableGroup {
  group: string
  unit?: Unit;
  shortcut?: string;
  prefix?: UnitPrefix;
  number?: boolean;
  decimal?: number;
  styleClass?: TableStyleClass;
  multiplier?: 1000 | 1024;
  trim: boolean;
}

export class TableColumn {
  order: number;
  value: (string | number)[];
  styleClass?: TableStyleClass;
  bgStatus?: Status;
  bgColor?: string;
  url?: string[];
  tooltip?: string;
  modal?: Box[];
  noTrim?: boolean;
}

export interface Box {
  type: BoxType;
  help?: Help
}

export class TextStyled {
  text: string;
  styleClass?: TextStyleClass;
}

export class TextBox extends TextStyled implements Box {
  type = BOX_TYPE.TextBox;
}

export class HtmlTextBox implements Box {
  type = BOX_TYPE.HtmlTextBox;
  html: string;
}

export class DashboardBox implements Box {
  type = BOX_TYPE.DashboardBox;
  groups: DashboardGroup[];
  width: number;
}

export class SunburstItem {
  item_id: string;
  label: string;
  parent_id: string;
  parent_label: string;
  value: number;
}

export class SunburstBox implements Box {
  type = BOX_TYPE.SunburstBox;
  items: SunburstItem[];
}

export class StaticMetric {
  type: ChartType;
  label: string;
  value: number;
  unit: Unit;
  shortcut: string;
}

export class GraphTableSort {
  metric: string;
  order: 'avg' | 'max';
}

export class GraphBase {
  aggregated?: boolean;
  label?: string;
  graphLabel?: string;
  parent?: GraphBaseParent;
  metrics: Metric[];
  minInterval?: number;
  peakMetric?: Metric;

  // custom properties in graph table
  properties?: GraphTableProperty[];

  // table sorting
  sort?: GraphTableSort;

  staticMetrics?: StaticMetric[];
  uuids: string[];
}

export class GraphBaseParent {
  label: string;
  subsystem: string;
}

export class GraphTableProperty {
  label: string;
  values: {
    [item_id: string]: string;
  }
}

export class MultiGraphBox implements Box {
  type = BOX_TYPE.MultiGraphBox;
  graphs: GraphBaseInterval[];
}

export class GraphBaseInterval extends GraphBase {
  interval: GraphInterval;
}

export class GraphBaseTimeRange extends GraphBase {
  start: number; // in secs
  end: number; // in secs
}

export class SingleTimeRangeGraphBox extends GraphBaseTimeRange implements Box {
  type = BOX_TYPE.SingleTimeRangeGraphBox;
}

export class SingleGraphBox extends GraphBaseInterval implements Box {
  type = BOX_TYPE.SingleGraphBox;
}

export class PredictionMetadata {
  label: string;
  key: string;
  number: boolean;
}

export class PredictionGraphBox extends PredictionBase implements Box {
  type = BOX_TYPE.PredictionGraphBox;
  graphLabel: string;
  metric: Metric;
  other_metrics: Metric[];
  metadata: PredictionMetadata[];
  lowerMax?: number;
  help?: Help;
}

export class Help {
  text: string;
  url: string;
}

export class FourGraphBox extends GraphBase implements Box {
  type: BoxType = BOX_TYPE.FourGraphBox;
  // aggregated?: boolean;
  // metrics: Metric[];
  // uuids: string[];
}

export class RegroupGraph extends GraphBase {
  url: string;
  tab: string;
}

export class RegroupButtonBox implements Box {
  type = BOX_TYPE.RegroupButtonBox;
  graphs: RegroupGraph[]
}


export class TableBase {
  header: TableHeader[];
  rows: TableColumn[][];
  fixedCols?: number;
  export?: Export;
  headerNoWrap?: boolean;
  orientation: OrientationType;
  headerNoUnit?: boolean;
}


export class TableBox extends TableBase implements Box {
  type = BOX_TYPE.TableBox;
}

export class TableGroupBox implements Box {
  type = BOX_TYPE.TableGroupBox;
  tables: TableBox[] | TableHeaderGroupBox[];
}

export class TableHeaderGroupBox implements Box {
  type = BOX_TYPE.TableHeaderGroupBox;
  header: TableGroupHeader[];
  rows: TableColumn[][];
  groups: TableGroup[];
  orientation: OrientationType;
}

export class TablePivotBox extends TableBase implements Box {
  type = BOX_TYPE.TablePivotBox;
}

export class Export {
  fileName?: string;
  url: string;
  method: ExportMethod;
  body?: {};
}

export class TopBoxTable extends TableBase {
  label: string;
  export: Export;
}

export class TopBox implements Box {
  type: BoxType = BOX_TYPE.TableBox;
  tables: TopBoxTable[];
}

export class PortHealthStatusPort {
  label: string;
  url: string;
  operationalStatus: string;
  operationalStatusColor: string;
  physicalStatus?: string;
  physicalStatusColor?: string;
  adminStatus?: string;
  adminStatusColor?: string;
  operationalStatusReason?: string;
  operationalStatusReasonColor?: string;
}

export class PortHealthStatusDevice {
  label: string;
  ports: PortHealthStatusPort[];
}

export class PortHealthStatusLegendStatus {
  color: string;
  label: string;
  text?: string;
}

export const PORT_STATUS_TYPE = ['operationalStatus', 'physicalStatus', 'adminStatus', 'operationalStatusReason'] as const;
export type PortStatusType = typeof PORT_STATUS_TYPE[number];

export class PortHealthStatusLegend {
  type: PortStatusType;
  label: string;
  text?: string;
  statuses: PortHealthStatusLegendStatus[];
}

export class PortHealthStatusHwType {
  label: string;
  devices: PortHealthStatusDevice[];
  legends: PortHealthStatusLegend[];
}

export class PortHealthStatusBox implements Box {
  type = BOX_TYPE.PortHealthStatusBox;
  hwTypes: PortHealthStatusHwType[];
}

export interface IHeatmap {
  label: string;
  metric: string;
  items: {
    item_id?: string;
    label: string;
    percent: number;
    url: string;
  }[];
}
export class HeatmapBox implements Box {
  type = BOX_TYPE.HeatmapBox;
  metrics: IHeatmap[];
}

export class OverviewRequest {
  item_id: string;
  start: number; // in secs
  end: number; // in secs
}

export class OverviewItem {
  label: string;
  item_id: string;
}

export class OverviewCategory {
  label: string;
  items: OverviewItem[];
}

export class OverviewBox implements Box {
  type = BOX_TYPE.OverviewBox;
  categories: OverviewCategory[];
}

export class CustomTopBox {
  type = BOX_TYPE.CustomTopBox;
  hw_type: string;
  subsystem: string;
  metrics: string[];
  parentsSubsystem?: string[];
  pageLabel?: string;
}

export class CustomTopRequest {
  hw_type: string;
  subsystem: string;
  type: 'avg' | 'max';
  metrics: string[];
  start: number; // in secs
  end: number; // in secs
  parentsSubsystem?: string[];
  pageLabel?: string;
}

export class StorageHeatmapInterval {
  color: string;
  limit: number;
}

export class StorageHeatmapItem {
  device: string;
  label: string;
  item_id: string;
  read?: StorageHeatmapItemMetric;
  write?: StorageHeatmapItemMetric;
  total?: StorageHeatmapItemMetric;
  url: string;
  sortValue?: number;
}


export class StorageHeatmapItemMetric {
  line: string;
  color: string;
  value?: number;
}


export class StorageHeatmapTier {
  items: StorageHeatmapItem[];
  read_intervals: StorageHeatmapInterval[];
  write_intervals: StorageHeatmapInterval[];
}

export class StorageHeatmapTiers {
  [tier: string]: StorageHeatmapTier;
}

export class StorageHeatmapBox implements Box {
  type = BOX_TYPE.StorageHeatmapBox;
  tiers: StorageHeatmapTiers;
}

export class TopologyConnection {
  sourceWwn: string;
  destinationWwn: string;
  sourceType: string;
  destinationType: string;
  percent: number;
  text: string;
}

export class TopologyItem {
  label: string;
  id?: string;
  wwn?: string;
  url?: string;
  children?: TopologyItem[];
}

export class TopologyGroup {
  label: string;
  type: TopologyItemType;
  children: TopologyItem[];
}

export class TopologyRequest {
  item_id?: string;
  type: TopologyType;
  hw_type?: string;
}

export class TopologyRequestItem extends TopologyRequest {
  label: string; // tab label
}

export class TopologyRequestBox implements Box {
  type = BOX_TYPE.TopologyRequestBox;
  topologies: TopologyRequestItem[];
}

export class TopologyBox implements Box {
  type = BOX_TYPE.TopologyBox;
  connections?: TopologyConnection[];
  groups: TopologyGroup[];
}

export class Tab<T extends Box = Box> {
  title: string;
  content?: T[];
  lazyContent?: string; // URL to get content
  export?: Export;
  help?: Help;
  subTabs?: SubTab[];
}

export type SubTab = Omit<Tab, "subTabs">;

export type MenuFormPageItem = { hwType: string, subsystem: string, class: string };
export class MenuFormPage {
  hwTypes: MenuFormPageItem[];
  title: string;
}

export class Page {
  title: string;
  tabs: Tab[];
  formPage?: MenuFormPage;

  constructor(title: string, tabs: Tab[], formPage?: MenuFormPage) {
    this.title = title;
    this.tabs = tabs;
    this.formPage = formPage;
  }
}

export class PageResponseDTO extends BasicResponseDTO {
  data: Page;
}

export class TabResponseDTO extends BasicResponseDTO {
  data: Tab;
}

export class OverviewResponseDTO extends BasicResponseDTO {
  data: Tab;
}

export class CustomTopResponseDTO extends BasicResponseDTO {
  data: Tab;
}
