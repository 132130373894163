import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useContext } from "react";
import { RuleContext } from "../Rule";
import { AllItemsContext } from '../comp/AllItems';
import { makeRegexApiData } from "../comp/preview/CgPreviewHandler";
import { usePreviewModal } from "../comp/preview/Preview";

function RegexList() {
  const { cls, subsystem: subsys, hw_type } = useContext(AllItemsContext);
  const { ruleIndex, parents, regex, linuxExclude } = useContext(RuleContext);
  const [showModal, contextHolder] = usePreviewModal(linuxExclude);

  const handlePreview = (regex: string) => () => showModal({
    cls,
    subsystem: subsys,
    hw_type,
    data: makeRegexApiData(cls, hw_type, subsys, [regex], parents, linuxExclude),
  });

  return <>
    <Form.List name={[ruleIndex, 'regex']} >
      {(regexItems, { remove }) => (
        <div className="xm-rule-filter-regex">
          {regexItems?.map(({ key, name }) => (
            <span className="xm-regex-item" key={`regex-list-${key}`}>
              <Input.Group compact className={!!!regex[name].length || !subsys ? 'xm-disabled-input' : 'xm-enable-input'}>
                <Form.Item name={name} rules={[{ required: true }]}>
                  <Input size="small" />
                </Form.Item>
                <Button
                  disabled={!!!regex[name].length || !subsys}
                  size="small"
                  onClick={!(!!!regex[name].length || !subsys) ? handlePreview(regex[name]) : undefined}
                  icon={<EyeOutlined />}
                />
              </Input.Group>
              <Button
                size="small"
                className={regex.length < 2 ? 'xm-disabled-delete' : 'hover-danger'}
                disabled={regex.length < 2}
                type='link'
                title='Remove rule'
                icon={<DeleteOutlined onClick={() => remove(name)} />}
              />
            </span>
          ))}
        </div>
      )}
    </Form.List>
    {contextHolder}
  </>;
}

export default RegexList;