import { ItemsResponseDTO } from '@dto/architecture.dto';
import { SelectProps } from 'antd/lib/select';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { isClassLPAR } from 'src/data/Class';
import { Log } from 'src/service/Log';

export type Option = { hwType?: string, label: string, value: string };
type OptionType = SelectProps<string, Option>['options'];
const useParentTypes = (cls: string, subsystem: string, hwType?: string): [OptionType, boolean] => {
  const [parents, setParents] = useState<OptionType>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isClassLPAR(cls)) {
      setLoading(true);
      const controller = new AbortController();
      const params = { class: cls, subsystem: 'device', childSubsystem: subsystem, strict_acl: false };
      axios.get<ItemsResponseDTO>(`/api/architecture/v1/items`, { signal: controller.signal, params }).then(
        ({ data: { data } }) => {
          if (data.length)
            setParents([{ label: 'device', value: 'device' }]);
          else
            setParents([]);
        },
        reason => Log.error('Failed to get parent list', reason)
      ).finally(() => setLoading(false));

      return () => controller.abort();
    }

    if (!hwType || !subsystem)
      return;

    setLoading(true);
    const controller = new AbortController();
    axios.get<ItemsResponseDTO>(`/api/architecture/v1/hw_types/${hwType}/subsystems/${subsystem}/parents`, { signal: controller.signal }).then(
      ({ data: { data } }) => setParents(data.map(({ subsystem, label, hw_type }) => ({ label, value: subsystem, hwType: hw_type }))),
      reason => Log.error('Failed to get parent list', reason)
    ).finally(() => setLoading(false));

    return () => controller.abort();
  }, [hwType, subsystem]);

  return [parents, loading];
};

export default useParentTypes;