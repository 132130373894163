import { BackendDTO } from "@dto/backendResponse.dto";
import { MailGroup } from "@dto/mailGroup.dto";
import { UserDTO } from "@dto/usersResponse.dto";
import axios from "axios";
import { ColorScale } from "plotly.js";
import { makeColorScaleFunc } from 'plotly.js/src/components/colorscale/helpers';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import React from "react";
import tinycolor from "tinycolor2";
import { AppState } from "../App";
import { AllType, RoutePath } from '../data/Routes';
import { MenuNode } from '../model/MenuNode';
import { Auth } from "../service/Auth";
import { MenuService } from "../service/Menu";
import { DateUtil } from "./DateUtil";
import { Deferred } from "./deferred";

type ReturnTypePromise<T> = T extends (...args: unknown[]) => Promise<infer R> ? R : unknown;

const PATH_NO_DEVICE = RoutePath.SETTINGS + RoutePath.CONFIGURATION + RoutePath.STORAGE;
const PATH_DEFAULT_DASHBOARD = RoutePath.DASHBOARD + RoutePath.DEFAULT;

const XORMON_NG = 'Xormon NG';

declare const publicUrl: string;

/**
 * Static utility class
 */
export class GLOB {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() { }

  /**
   * Checks PROD / DEV UI build
   * @returns true if running production build
   */
  static isProdBuild() {
    return import.meta.env.PROD;
  }

  /**
   * Checks PROD / DEV environment set on BE
   * @returns true if NODE_ENV=production set on BE
   */
  static isProdEnv() {
    return GLOB.getBackendInfo().backend.production || process.env.DUMMY_BE_PROD;
  }

  /**
   * Get context URL path
   */
  static getPublicUrl(): string {
    return GLOB.isProdBuild() && publicUrl || import.meta.env.VITE_PUBLIC_URL;
  }

  /**
   * For daily graphs refresh rate
   */
  static readonly TIMEOUT_SHORT = 1000 * 60 * 10;
  /**
   * For non-daily graphs refresh rate
   */
  static readonly TIMEOUT_LONG = 1000 * 60 * 60;

  /**
   * Short change events e.g. mouse click
   */
  static readonly TIMEOUT_DEBOUNCE_SHORT = 400;
  /**
   * Long change events e.g. keyboard input
   */
  static readonly TIMEOUT_DEBOUNCE_LONG = 800;

  /**
   * 1970-04-26T17:46:39.999Z
   */
  static readonly MIN_UNIX_TIME = 9999999999;
  /**
   * Logs view refresh rate
   */
  static readonly TIMEOUT_LOG = 10000;

  /**
   *  URL part preceding app PATH
   */
  static readonly SERVER_URL = import.meta.env.VITE_SERVER + (import.meta.env.PROD && GLOB.getPublicUrl() || '');

  /**
   * URL for WebSocket
   */
  static readonly SERVER_WS_URL = (import.meta.env.VITE_SERVER_WS ||
    ((window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host)) +
    (import.meta.env.PROD && GLOB.getPublicUrl() || '');

  /**
   * Color CSS value for no data in heatmap
   */
  static readonly HEATMAP_NO_DATA_COLOR = 'lightgray';
  /**
   * Array containing arrays mapping a normalized value to an rgb, rgba, hex, hsl, hsv, or named color string.
   * At minimum, a mapping for the lowest (0) and highest (1) values are required.
   */
  static readonly COLOR_SCALE: ColorScale = [[0, 'green'], [0.25, 'lightgreen'], [0.5, 'yellow'], [0.75, 'orange'], [1, 'red']];
  /**
   *  Get scaled color array from COLOR_SCALE for provided value
   */
  static readonly scaleColor: (value: number) => number[] = makeColorScaleFunc({
    domain: (GLOB.COLOR_SCALE as [number, string][]).map(value => value[0] * 100),
    range: (GLOB.COLOR_SCALE as [number, string][]).map(value => value[1])
  }, { returnArray: true });
  /**
   * Convert color array to rgba string
   * @param colorArray
   * @returns
   */
  static colorArray2rgba(colorArray: number[]) {
    const colorObj = {
      r: colorArray[0],
      g: colorArray[1],
      b: colorArray[2],
      a: colorArray[3]
    };
    return tinycolor(colorObj).toRgbString();
  }
  /**
   * Get contrast color (black or white) for provided color array
   * @param rgb
   * @returns
   */
  static contrastColor(rgb: number[]) {
    const brightness = Math.round(((rgb[0] * 299) +
      (rgb[1] * 587) +
      (rgb[2] * 114)) / 1000);
    return (brightness > 125) ? 'black' : 'white';
  }

  /**
   * Reference to Auth Service
   */
  static readonly authService = new Auth();

  /**
   * Reference for Menu Service
   */
  static readonly menuService = new MenuService();

  /**
   * Resolved when left tree menu is loaded
   */
  static readonly treeLoaded = new Deferred();

  /**
   * Resolved when backend info is obtained
   */
  static readonly infoLoaded = new Deferred();

  /**
   * User is logged in and required requests are resolved
   */
  static readonly xormonReady = new Deferred();

  /**
   * Load only graph items upto this limit
   */
  static graphTopItems = 20;

  /**
   * Graph traces colors
   */
  static colorPalette: string[];


  private static _userInfo: UserDTO;
  /**
   * Get currently logged-in user information
   */
  static get userInfo(): Readonly<UserDTO> { return GLOB._userInfo; }
  static set userInfo(value: UserDTO) {
    GLOB._userInfo = value;
    DateUtil.setOffset(value.timezone);
  }

  //#region routing

  /**
   * AbortController for navigation
   */
  static navAborter: AbortController;

  /**
   * Class path e.g.: /storage
   */
  static selectedClass: RoutePath;// string;
  /**
   * All type path </type, /group, /device>
   */
  static selectedAllType: AllType;
  /**
   * Storage group name e.g.: DC1
   */
  static selectedGroup: string;
  /**
   * HW type path when allType=<null|type> e.g. /ISILON
   * or device path when allType=<group|device> e.g. /Isilon200
   */
  static selectedType: string;

  private static _selectedItem: MenuNode;
  /**
   * Left menu tree node according to last part of current route
   */
  static get selectedItem(): MenuNode {
    return this._selectedItem;
  }
  static set selectedItem(item: MenuNode) {
    this._selectedItem = item;
    setTimeout(() => {
      if (!GLOB.setSelectedKeys || !GLOB.setExpandedKeys) return;
      if (item) {
        this.setSelectedKeys([item.key as string]);
        this.setExpandedKeys(prev => [...prev, ...MenuNode.getParentKeys(item.isLeaf ? item.parent : item)].filter(GLOB.uniqueArrayFilter));
        document.title = XORMON_NG + ' - ' + MenuNode.getParentNodes(item).map(mn => mn.titleText || mn.title).join(' | ');
      }
      else {
        this.setSelectedKeys([]);
        this.setExpandedKeys([]);
        document.title = XORMON_NG;
      }
    });
  }

  static getGroup(node: MenuNode = GLOB.selectedItem): MenuNode {
    if (!node?.parent) return;
    if (node.parent?.path === RoutePath.GROUP) return node;
    return GLOB.getGroup(node.parent);
  }

  //#endregion

  //#region state

  /**
   * Set left menu selected keys
   */
  static setSelectedKeys: React.Dispatch<React.SetStateAction<(string | number)[]>>;
  /**
   * Set left menu tree expanded keys
   */
  static setExpandedKeys: React.Dispatch<React.SetStateAction<string[]>>;
  /**
   * Get left menu tree expanded keys
   */
  static getExpandedKeys: () => string[];
  /**
   * Scroll left menu tree to show selected item
   */
  static scrollSelectedItemIntoView: () => void;

  static getBackendInfo: () => BackendDTO;
  static setBackendInfo: (info: BackendDTO) => void;
  static getEmailGroups: () => MailGroup[];
  static setEmailGroups: (groups: MailGroup[]) => void;
  static getState: () => Readonly<AppState>;
  static setState: <K extends keyof AppState>(
    state:
      | ((prevState: Readonly<AppState>, props: Readonly<unknown>) => Pick<AppState, K> | AppState | null)
      | (Pick<AppState, K> | AppState | null),
    callback?: () => void
  ) => void;
  static refresh() {
    if (GLOB.getState && GLOB.setState) {
      GLOB.setState({ refresh: !GLOB.getState().refresh });
    }
  }

  static setTree: React.Dispatch<React.SetStateAction<MenuNode>>;
  static getTree: () => MenuNode;

  static getMenu: () => MenuNode[];
  static setMenu: React.Dispatch<React.SetStateAction<MenuNode[]>>;

  //#endregion

  //#region functions

  /**
   * Checks if enterprise edition license is valid
   * @returns true if active enterprise license
   */
  static isValidEnterprise() {
    return GLOB.getBackendInfo().backend.premium.valid && !GLOB.getBackendInfo().backend.free;
  }

  /**
   * Get menu with content (children) only
   * @returns filtered menu
   */
  static getActiveMenu() {
    return GLOB.getMenu()?.filter(mn => mn.children?.length);
  }

  private static getActiveMenuClasses() {
    return GLOB.getMenu()?.filter(mn => mn.notActive === false);
  }
  /**
   * Get default route path
   * @returns
   */
  static getDefaultPath() {
    return GLOB.getActiveMenuClasses().length || !GLOB.userInfo.isAdmin ? PATH_DEFAULT_DASHBOARD : PATH_NO_DEVICE;
  }

  /**
   * Get last path of URL
   * @param url
   * @returns
   */
  static getItemPath(item: { url?: string; group_id?: number }) {
    if (item.url) {
      return item.url.substring(item.url.lastIndexOf('/'));
    } else if (item.group_id) return '/' + item.group_id;
  }

  /**
   * Returns promise, which is resolved after timeout with result of callback
   * @param callback
   * @param timeout
   * @returns
   */
  static timeoutPromise<T = void>(callback: () => T, timeout?: number) {
    return new Promise<T>(resolve => setTimeout(() => {
      resolve(callback ? callback() : null);
    }, timeout));
  }

  /**
   * Executes callback after specified number of timeout cycles
   * @param callback function to call
   * @param count number of timeout cycles, executes callback immediately when count <= 0
   */
  static multiTimeout(callback: () => void, count = 1) {
    window.setTimeout(() => {
      if (count > 0)
        GLOB.multiTimeout(callback, count - 1);
      else
        callback();
    });
  }

  /**
   * Waits 1sec for element to appear in specified root DOM
   * @param selector
   * @param root
   * @returns
   */
  static waitForElm(selector, root: HTMLElement) {
    return new Promise<HTMLElement>((resolve, reject) => {
      if (root.querySelector(selector)) {
        return resolve(root.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
        if (root.querySelector(selector)) {
          resolve(root.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(root, {
        childList: true,
        subtree: true
      });

      setTimeout(() => {
        observer.disconnect();
        reject('Element not found within timeout!');
      }, 1000);
    });
  }

  /**
   * Async debounce wrapper. Lodash debounce doesn't handle async functions.
   */
  static debounceAsync<T extends (...args: Parameters<T>) => Promise<ReturnTypePromise<T>>>(func: T, wait: number): (...args: Parameters<T>) => Promise<ReturnTypePromise<T>> {
    let timerID = -1;

    return (...args) => {
      window.clearTimeout(timerID);

      const promiseForFunc = new Promise((resolve) => {
        timerID = window.setTimeout(resolve, wait);
      });

      return promiseForFunc.then(() => func(...args));
    };
  }

  /**
   * Removes null values
   * @param value
   * @param index
   * @param array
   * @returns
   */
  static uniqueArrayFilter(value, index: number, array: unknown[]) {
    return value && array.indexOf(value) === index;
  }

  /**
   * Keeps null values
   * @param value
   * @param index
   * @param array
   * @returns
   */
  static uniqueArrayFilterNull(value, index: number, array: unknown[]) {
    return array.indexOf(value) === index;
  }

  /**
   * Sorter function, case insensitive, numeric collation '2<10'
   * @param a
   * @param b
   * @returns
   */
  static naturalSort(a, b) {
    const as = typeof a === 'string' ? a : String(a);
    const bs = typeof b === 'string' ? b : String(b);
    return as?.localeCompare(bs, undefined, { sensitivity: 'base', numeric: true });
  }

  /**
   * Returns Boolean after check Regex pattern
   * @param value text to check
   * @returns true if input valid
   */
  private static isValidTitle(value: string) {
    const regex = /^[a-zA-Z0-9_&@,.À-ÖØ-öø-ÿěščřžýáíéúůňďťĚŠČŘŽÝÁÍÉÚŮŇĎŤа-яА-ЯІі\s-]+$/
    return regex.test(value)
  }
  static readonly TitleCharactersValidatorRule: ValidatorRule = {
    validator(rule, value, callback) {
      if (GLOB.isValidTitle(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Valid characters: alphanumeric with diacritic - _ & @ , . "));
    }
  }

  //#endregion
}

axios.defaults.baseURL = GLOB.SERVER_URL;
axios.defaults.withCredentials = true;