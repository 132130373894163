import { FC, useState } from "react";
import { useMatch } from "react-router-dom";
import SetLogMenuNode from "src/content/tools/logs/SetLogMenuNode";
import { RoutePath } from "src/data/Routes";
import { GLOB } from "src/util/Glob";
import NavigateRoute from "./NavigateRoute";

export const RouteSettingsHelp: FC<{ match: string }> = ({ match }) => {
  const pathMatch = useMatch(match);
  const [isReady, setIsReady] = useState(false);

  if (!isReady) {
    void GLOB.xormonReady.promise.then(() => setIsReady(true));
    return <></>;
  }

  const subtype = pathMatch.params.subtype;
  if (subtype === pathSlice(RoutePath.SET_LOGS)) {
    GLOB.selectedClass = RoutePath.SETTINGS;
    GLOB.selectedType = RoutePath.HELP;
    GLOB.selectedAllType = null;

    return <SetLogMenuNode key={`help-menu-${subtype}`} />;
  }

  const cls = pathSlice(RoutePath.SETTINGS);
  const type = pathSlice(RoutePath.HELP);

  return (
    <SetLogMenuNode key={`help-menu-${subtype}`} >
      <NavigateRoute clsName={cls} typeOrDevice={type} itemName={subtype} />
    </SetLogMenuNode>
  );
}

const pathSlice = (path: RoutePath) => path.slice(1);
