import { Item } from '@dto/architecture.dto';
import { Empty, Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GLOB } from 'src/util/Glob';
import { ClassEnum, ClassType } from '../../data/Class';
import "./Search.less";
import SearchTab from './SearchTab';
import useSearch from './useSearch';
import { Log } from 'src/service/Log';

interface Tab {
  label: string;
  class: string;
  items: Item[];
}

function useQuery(): [string, URLSearchParams] {
  const { pathname, search } = useLocation();

  return React.useMemo(() => [pathname, new URLSearchParams(search)], [search]);
}

const Search = () => {
  const [path, query] = useQuery();
  const search = query.has('find') && query.get("find");
  const tagId = query.has('tag_id') && query.get("tag_id");
  const tagName = query.has('tag_name') && query.get("tag_name");
  const search_str = search || "";

  const api = tagId ? `/api/tag/v1/items/id/${tagId}` : `/api/search/v1/item/${encodeURIComponent(search_str)}`;
  const [items, isLoading] = useSearch(path, tagId ? `tag:${tagName}` : search_str, api);
  const [addClass, setAddClass] = useState<string[]>([]);

  if (isLoading || !items.length) {
    return <div className="xm-search-fail">
      {isLoading ? <Spin tip="Loading..." /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />}
    </div>;
  }

  if (!search) {
    return <div className="xm-search-fail">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
           </div>;
  }

  const tabs: Tab[] = [
    { label: "Server", class: ClassEnum.SERVER, items: [] },
    { label: "Storage", class: ClassEnum.STORAGE, items: [] },
    { label: "SAN", class: ClassEnum.SAN, items: [] },
    { label: "LAN", class: ClassEnum.LAN, items: [] },
  ];

  items.forEach(item => {
    for (const tab of tabs) {
      if (tab.class === item.class) {
        tab.items.push(item);
        return;
      }
    }

    const label = item.class.charAt(0).toUpperCase() + item.class.slice(1);
    tabs.push({ label: label, class: item.class, items: [item] });

    if (!addClass.includes(item.class) && !isClass(item.class)) {
      console.warn(`Add Class '${item.class}'`);
      addClass.push(item.class);
      setAddClass(prev => ([...prev, item.class]))
    }
  });

  return (
    <Tabs items={tabs.map(tab => {
      if (!tab.items.length)
        return;

      return {
        key: tab.label,
        label: tab.label,
        className: 'xm-search-tab-wrapper',
        children: <SearchTab key={tab.class} items={tab.items.sort((a, b) => GLOB.naturalSort(a.label, b.label))} />
      }
    })} />
  );
}

function isClass(value: string): value is ClassType {
  return Object.values<string>(ClassEnum).includes(value);
}

export default Search;