import { Item, ItemsResponseDTO } from "@dto/architecture.dto";
import { Form, Select } from "antd";
import { useContext, useRef } from "react";
import { isClassLPAR } from "src/data/Class";
import useGetApiData from "src/hook/useGetApiData";
import { AllItemsContext } from "../comp/AllItems";
import ModalSelectItems from "../comp/ModalSelectItems";
import { Option } from "../hook/useParentTypes";

export interface FormSelectData {
  subsystem: string;
  items: Item[];
}

function ModalSelectItemsParents({ parentTypes }: { parentTypes: Option[] }) {
  const { cls, subsystem, hw_type } = useContext(AllItemsContext);
  const form = Form.useFormInstance<FormSelectData>();
  const activeSelectOption = Form.useWatch<string>('subsystem', form);
  const [activeParentType] = activeSelectOption ? activeSelectOption.split("/") : [null];
  const activeParentHwType = useRef('');
  const apiParm = { class: cls, subsystem: activeParentType, strict_acl: false };
  const [possibleParents, , isLoadingPossibleParent] = useGetApiData<Item[], ItemsResponseDTO>(
    `/api/architecture/v1/items`,
    `Failed to get parent options`,
    [activeSelectOption, cls, hw_type, subsystem],
    [],
    !!activeParentType ? 'YES' : 'NO',
    true,
    isClassLPAR(cls) ? { ...apiParm, hw_type: hw_type !== cls ? hw_type : activeParentHwType.current } : { ...apiParm, childSubsystem: subsystem }
  );

  function makeItemOfList({ label, item_id, subsystem }: Item) {
    return hw_type ? { key: item_id, title: `${subsystem}/${label}` } : { key: item_id, title: label };
  }

  const handleChange = (_: string, { hwType }: Option) => {
    activeParentHwType.current = hwType;
  };

  return (
    <ModalSelectItems<Item, FormSelectData>
      makeItemOfList={makeItemOfList}
      makeLabel={({ label }) => label}
      isLoading={isLoadingPossibleParent}
      treeItems={possibleParents}
      handleCheckItem={(item) => item}
      treeSelectProps={{ disabled: !activeParentHwType.current && isClassLPAR(cls) }}
    >
      <Form.Item name='subsystem' noStyle>
        <Select
          size='middle'
          placeholder="Subsystem"
          disabled={!isClassLPAR(cls)}
          onChange={handleChange}
          options={parentTypes.map(parent => ({
            ...parent,
            value: `${parent.value}/${parent.hwType}`,
          }))}
        />
      </Form.Item>
    </ModalSelectItems>
  )
}

export default ModalSelectItemsParents;
