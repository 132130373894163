import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash-es';
import { HostCfgItem } from '../ConfigurationTypes';

const devices: HostCfgItem[] = [];

export const deviceSlice = createSlice({
  name: 'devices',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: devices,
  reducers: {
    add: (state, device: PayloadAction<HostCfgItem>) => {
      state.push(device.payload);
    },
    remove: (state, id: PayloadAction<string>) => {
      for (let index = 0; index < state.length; index++) {
        if (state[index].hostcfg_id === id.payload) {
          state.splice(index, 1);
          break;
        }
      }
    },
    merge: (state, data: PayloadAction<HostCfgItem>) => {
      _.merge(state.find(hci => hci.hostcfg_id === data.payload.hostcfg_id), data.payload);
    },
    replaceStore: (state, replacement: PayloadAction<HostCfgItem[]>) => {
      return replacement.payload;
    }
  },
});