import { Tabs, TabsProps } from "antd"
import { FC } from "react"

const TabsSticky: FC<TabsProps> = ({ children, ...props }) => {
  return (
    <Tabs tabBarStyle={{ position: "sticky", top: 0, zIndex: 88, background: "#fff" }} {...props}>
      {children}
    </Tabs>
  )
}

export default TabsSticky
