export enum HwType {
  // virtualization
  NUTANIX = 'nutanix',
  ORACLEVM = 'oraclevm',
  OVIRT = 'ovirt',
  POWER = 'power',
  PROXMOX = 'proxmox',
  WINDOWS = 'windows',
  VMWARE = 'vmware',
  // cloud
  AMAZON = 'aws',
  AZURE = 'azure',
  GCLOUD = 'gcloud',
  CLOUDSTACK = 'cloudstack',
  // container
  DOCKER = 'docker',
  KUBERNETES = 'kubernetes',
  OPENSHIFT = 'openshift',
  // DB
  POSTGRES = 'postgres',
  SQLSERVER = 'sqlserver',
  ORACLEDB = 'oracledb',
  DB2 = 'db2',
}

export enum HwTypeStor {
  SWIZ = 'swiz', VSPG = 'vspg', DOTHILL = 'dothill', ESERIES = 'eseries', //DS5K = 'ds5k',
  // DELL
  ISILON = 'isilon', VNX = 'vnx', DATADOMAIN = 'datadomain', ECS = 'ecs', EQUALOGIC = 'equalogic',
  POWERFLEX = 'powerflex', VMAX = 'vmax', POWERSTORE = 'powerstore', COMPELLENT = 'compellent', UNITY = 'unity',
  VNXE = 'vnxe', VNXF = 'vnxf', VPLEX = 'vplex', XTREMIO = 'xtremio',
  // IBM
  DS8K = 'ds8k', XIV = 'xiv', GPFS = 'gpfs', IBMCOSS = 'ibmcoss',
  // HITACHI
  HUS = 'hus', HCP = 'hcp', HNAS = 'hnas',
  // HPE
  STOREVIRTUAL = 'storvirtual', NIMBLE = 'nimble', PAR3 = '3par', STOREONCE = 'storeonce',
  // NetApp
  NETAPP = 'netapp', SOLIDFIRE = 'solidfire', STORAGEGRID = 'storagegrid',
  // Promise
  VTRAK = 'vtrak',
  // PureStorage
  PURE = 'pure', PUREBLADE = 'pureblade',
  // Huawei
  OCEANSTOR = 'oceanstor',
  // Infinidat
  INFINIBOX = 'infinibox', INFINIGUARD = 'infiniguard',
  // Infortrend
  INFORTREND = 'infortrend',
  // Ceph
  CEPH = 'ceph',
  // Cohesity
  COHESITY = 'cohesity',
  // DataCore
  DATACORE = 'datacore',
  // DataDirect Networks
  DDNINTELLIFLASH = 'ddnintelliflash',
  // FalconStor
  FALCONSTOR = 'falconstor',
  // Fujitsu
  ETERNUS = 'eternus',
  // iXsystems
  FREENAS = 'freenas',
  // MacroSAN
  MACROSAN = 'macrosan',
  // Oracle
  ORACLEZFS = 'oraclezfs',
  // QNAP Systems
  QNAP = 'qnap',
  // Qumulo
  QUMULO = 'qumulo',
  // Rubrik
  RUBRIK = 'rubrik',
  // Synology
  SYNOLOGY = 'synology',
  // Veritas
  NETBACKUP = 'netbackup',

  // SAN
  SANBRCD = 'sanbrcd',
  SANCISCO = 'sancisco',

  // SANnav

  // LAN
  LANCISCO = 'lancisco',
  LANOTHERS = 'lanothers'
}
