import { DefaultDashboardDTO, GroupDashboardFolder } from "@dto/dashboard.dto";
import { MenuItem, MenuResponseDTO } from "@dto/menuResponse.dto";
import axios from "axios";
import { isEqual, pick } from "lodash-es";
import { Component, ReactNode } from "react";
import { NavigateOptions, To } from "react-router-dom";
import { Spinner } from "src/component/spinner/Spinner";
import { BasePage } from "src/content/basePage/BasePage";
import { ErrorPage } from "src/content/errorBoundary/ErrorPage";
import { API_URL } from "src/data/Api";
import { ClassEnum } from "src/data/Class";
import { AllType, RoutePath, isDevicesTree } from "src/data/Routes";
import { menuItemsRight } from "src/data/menu-items";
import { getHwTypeParent, getTopParent, mapParent } from "src/menu/left/SideNavBar";
import { MenuNode } from "src/model/MenuNode";
import { AppGlobProps, appGlobConnector } from "src/redux/store";
import { Log } from "src/service/Log";
import { MenuService } from "src/service/Menu";
import { GLOB } from "src/util/Glob";
import { WithRouter, withRouter } from "src/util/router";
import { EmptyPage } from "./EmptyPage";

export class NavRouteProps {
  clsName: string = null;
  allType?: AllType = null;
  groupName?: string = null;
  typeOrDevice?: string = null;
  itemName: string = null;
  search: string = null;
}

const NAV_ROUTE_PROPS = Object.keys(new NavRouteProps());

class NavResult {
  nav: { to: To, options?: NavigateOptions };
  tree: MenuNode;
  item: MenuNode;
}

interface NavState {
  navItem: MenuNode;
  loading: boolean;
  search: string;
}

export function itemsToNodes(items: MenuItem[], group?: string): MenuNode[] {
  if (!items) return;
  return items.map((mi) => ({ ...MenuNode.menuItemToNode(mi, group), children: itemsToNodes(mi.children, group) }));
}

function equals(a: MenuItem, b: MenuNode) {
  return a.url && a.url === b.url || b.group_id && (a as GroupDashboardFolder).group_id === b.group_id;
}

export function mergeTree(current: MenuNode[], incoming: MenuItem[], group?: string) {
  if (!current || !incoming) return false;
  let updated = false;
  for (const inc of incoming) {
    let found: MenuNode;
    for (let i = 0; i < current.length; i++) {
      const cur = current[i];
      if (equals(inc, cur)) {
        found = cur;
        if (cur.children) {
          if (cur.subsystem === "group" && inc.children) {
            cur.children = itemsToNodes(inc.children, group);
            updated = true;
            current[i] = { ...cur };
          } else {
            const result = mergeTree(cur.children, inc.children, group);
            if (result) {
              updated = true;
              current[i] = { ...cur };
            }
          }
        } else if (inc.children) {
          cur.children = itemsToNodes(inc.children, group);
          if (GLOB.selectedClass === RoutePath.CUSTOM_GROUPS || GLOB.selectedClass === RoutePath.DASHBOARD)
            cur.children.sort(MenuService.getFolderSorter(false));
          current[i] = { ...cur };
          updated = true;
        }
        break;
      }
    }
    if (!found) {
      current.push(itemsToNodes([inc], group)[0]);
      updated = true;
    }
  }
  return updated;
}

export function getPathUrl(clsPath: RoutePath, allType: AllType, groupPath: string, typeOrDevicePath: string, itemSearch: string) {
  let typePath = typeOrDevicePath;
  if (isDevicesTree(allType)) {
    const typeOrDevice = typeOrDevicePath?.substring(1);
    const dev = GLOB.menuService.storagesBy.device.children.find(mn => mn.title === typeOrDevice);
    typePath = dev ? '/' + dev.hwType : typeOrDevicePath;
  } else if (GLOB.selectedAllType === RoutePath.GROUP) {
    typePath = null;
  } else if (itemSearch === "/groupconfiguration" && clsPath as string !== "/virtualization") {
    typePath = "/lan";
  }
  switch (clsPath) {
    case RoutePath.CUSTOM_GROUPS:
      return API_URL.CUSTOM_GROUP_MENU + RoutePath.GET_PATH + itemSearch;
    case RoutePath.DASHBOARD:
      if (itemSearch === RoutePath.DEFAULT) {
        return API_URL.DASHBOARD + RoutePath.DEFAULT;
      } else
        return API_URL.DASHBOARD_MENU + RoutePath.GET_PATH + itemSearch;
    default:
      if (typePath) {
        return API_URL.MENU + RoutePath.GET_PATH + (groupPath || '') + typePath + itemSearch;
        // } else if (GLOB.selectedItem?.parent?.path === RoutePath.GROUP) {
        //   urlGetPath = API_URL.MENU + RoutePath.GET_PATH + '/' + groupName + itemSearch;
      } else {
        const groupId = GLOB.getGroup(GLOB.selectedItem)?.itemId;
        const sgroupId = itemSearch.includes('sgroup') || !groupId ? '' : ('&sgroup_id=' + groupId);
        return API_URL.MENU + '/getpath' + RoutePath.SGROUP + itemSearch + sgroupId;
      }
  }
}

class NavigateRoute extends Component<WithRouter & AppGlobProps & NavRouteProps, NavState> {

  private aborter: AbortController;

  constructor(props) {
    super(props);
    this.state = { loading: true, navItem: null, search: null };
  }

  componentDidUpdate(prevProps: Readonly<WithRouter & AppGlobProps & NavRouteProps>, prevState: Readonly<NavState>, snapshot?): void {
    if (!isEqual(pick(prevProps, NAV_ROUTE_PROPS), pick(this.props, NAV_ROUTE_PROPS)) || prevState.search !== this.state.search) {
      this.navigate();
    }
  }
  componentWillUnmount(): void {
    this.aborter?.abort();
  }

  componentDidMount(): void {
    this.navigate();
  }

  private navigate() {
    GLOB.navAborter?.abort();
    const aborter = this.aborter = GLOB.navAborter = new AbortController();
    this.setState({ loading: true });

    GLOB.xormonReady.promise.then(() => {
      void GLOB.timeoutPromise(async () => {
        if (aborter.signal.aborted) return;
        const result = await this.getItem(aborter.signal);
        if (aborter.signal.aborted) return;
        if (!result) {
          this.setState({ loading: false });
          return;
        }
        setTimeout(() => {
          if (aborter.signal.aborted) return;
          if (result.tree) {
            GLOB.treeLoaded.reset();
            GLOB.setTree(result.tree);
          }
          if (result.nav) {
            this.props.navigate(result.nav.to, result.nav.options);
            return;
          }
          if (result.item)
            this.setState({ navItem: result.item });
          else if (result.tree || GLOB.getTree()?.children) {
            if ('/' + this.props.clsName !== GLOB.selectedClass) {
              Log.error('No page for route ' + this.props.location.pathname);
            }
            this.setState({ navItem: { key: 'empty', component: <EmptyPage /> } });
          }
          else {
            const defaultPath = GLOB.getDefaultPath();
            if (this.props.location.pathname === defaultPath) {
              this.setState({ navItem: { key: 'noItem', component: <ErrorPage detail={'No page for route ' + defaultPath} /> } });
            } else {
              Log.error('No menu found for specified URL', this.props);
              this.props.navigate(defaultPath);
              return;
            }
          }
          this.setState({ loading: false });
        });
      });

    }, reason => console.log(reason));

  }

  private async getItem(abortSignal: AbortSignal): Promise<NavResult> {
    const result = new NavResult();
    const { clsName, allType, groupName, typeOrDevice, itemName } = this.props;
    const clsPath = '/' + clsName as RoutePath;
    const typeOrDevicePath = typeOrDevice ? '/' + typeOrDevice : null;
    let cls = menuItemsRight.find((mn) => mn.path === clsPath);
    if (!cls) {
      if (clsPath === GLOB.selectedClass &&
        (allType && allType === GLOB.selectedAllType || !allType && typeOrDevicePath === GLOB.selectedType)) {
        cls = GLOB.getTree();
      }
      else {
        cls = GLOB.getActiveMenu().find((mn) => mn.path === clsPath);
      }
    }
    if (!cls) {
      return result;
    }
    const key = cls.keyClass || cls.key as string;
    if (!this.props.topMenuSelectedKeys.some(s => s === key)) {
      setTimeout(() => {
        this.props.setTopMenuSelectedKeys([key]);
      });
    }
    const hwTypePath = allType && typeOrDevice ? typeOrDevicePath + '/' : null;
    const itemPath = '/' + itemName;
    const itemSearch = itemPath + this.props.location.search;
    const itemHash = this.props.location.hash;
    let nodes: MenuNode[];
    let groupPath = '';
    let group: string;
    let groupNode: MenuNode;
    if (key === ClassEnum.STORAGE && (GLOB.selectedAllType || allType)) {
      switch (allType || GLOB.selectedAllType) {
        case RoutePath.DEVICE:
          cls = GLOB.selectedClass === clsPath && GLOB.selectedAllType === allType ? cls : GLOB.menuService.storagesBy.device;
          break;
        case RoutePath.GROUP:
          cls = GLOB.selectedClass === clsPath && GLOB.selectedAllType === allType ? cls : GLOB.menuService.storagesBy.group;
          group = allType ? groupName : GLOB.selectedGroup;
          if (group) {
            groupPath = '/' + group;
            groupNode = cls.children.find(mn => mn.title === group);
            if (!groupNode) {
              Log.warn(`No group "${group}" found.`);
              result.nav = { to: [clsPath, allType].join('') };
              return result;
            }
            nodes = groupNode.children;
          } else {
            nodes = cls.children;
          }
          break;
        case RoutePath.TYPE:
          cls = GLOB.selectedClass === clsPath && GLOB.selectedAllType === allType ? cls : GLOB.menuService.storagesBy.type;
          break;
        default:
          Log.error('Unknown type ' + (allType || GLOB.selectedAllType));
      }
      const storages = GLOB.menuService.storagesBy;
      switch (allType) {
        case RoutePath.DEVICE:
          cls.title = storages.device.title;
          break;
        case RoutePath.GROUP:
          cls.title = storages.group.title;
          break;
        case RoutePath.TYPE:
          cls.title = storages.type.title;
          break;
      }
    }

    if (!nodes) nodes = cls.children;
    let typ = isDevicesTree(allType) ?
      typeOrDevice ? nodes?.find(mn => mn.title === typeOrDevice) : cls
      : typeOrDevice && !cls.hwType ? nodes?.find((mn) => mn.path === typeOrDevicePath || mn.hwType === typeOrDevice) : cls;
    if (!typ && groupNode) typ = groupNode;
    if (!typ && ["lanothers", "lancisco"].includes(typeOrDevice)) {
      let split = itemSearch.split(":::");
      if (split.length > 1) {
        for (const node of nodes) {
          if (node.subsystem === "group" && node.itemId === split[1]) {
            typ = node;
            typ.hwType = typeOrDevice;
          }
        }
      } else {
        for (const node of nodes) {
          if (node.subsystem === "group") {
            typ = node;
            typ.hwType = typeOrDevice;
          }
        }
      }
    }
    if (GLOB.selectedClass === clsPath && isDevicesTree(GLOB.selectedAllType)
      && (!typ || typ.title !== typeOrDevice) && nodes?.length) {
      typ = { key: 'dummyType', children: typeOrDevice ? nodes.filter(mn => mn.hwType === typeOrDevice) : typ.children };
      if (!typ.children?.length) {
        typ.children = [];
        for (const grup of nodes) {
          const types = grup.children?.filter(child => child.hwType === typeOrDevice);
          if (types?.length) {
            for (const t of types) {
              t.parent = grup;
            }
            typ.children.push(...types);
          }
        }
      }
      const item = MenuNode.findDeepNode(typ.children, itemSearch, typeOrDevicePath + '/', group);
      if (item) {
        mapParent(cls.children, cls);
        const device = getHwTypeParent(item);
        const devicePath = device ? ('/' + device.titleText) : '';
        const path = clsPath + GLOB.selectedAllType + groupPath + devicePath + itemSearch;
        if (path !== (decodeURIComponent(this.props.location.pathname + this.props.location.search))) {
          result.nav = { to: clsPath + GLOB.selectedAllType + groupPath + devicePath + itemSearch + itemHash, options: { replace: true } };
          return result;
        }
        GLOB.selectedGroup = group;
        GLOB.selectedType = typeOrDevicePath;
        result.item = GLOB.selectedItem = item;
        result.tree = cls;
        return result;
      }
    }
    const root = (allType || group) ? cls : typ;
    if (!typ) {
      if (!typeOrDevice)
        result.item = cls;
      return result;
    }
    if (GLOB.selectedAllType && GLOB.selectedAllType !== RoutePath.GROUP && !allType && GLOB.selectedClass === clsPath && typ && itemName) {
      result.nav = { to: clsPath + GLOB.selectedAllType + groupPath + typeOrDevicePath + itemSearch + itemHash, options: { replace: true } };
      return result;
    }
    if (!itemName) {
      if (typ.children?.length &&
        (GLOB.selectedClass !== clsPath || GLOB.selectedAllType !== allType || GLOB.selectedType !== typeOrDevicePath || isDevicesTree(allType))) {
        const child = typ.children.find(child => child.isLeaf);
        if (child) {
          result.nav = { to: [clsPath, allType, typeOrDevicePath, MenuNode.getNodePath(child)].join(''), options: { replace: true } };
        }
      }

      GLOB.selectedClass = clsPath;
      GLOB.selectedAllType = allType;
      GLOB.selectedType = typeOrDevicePath;
      GLOB.selectedGroup = group;
      GLOB.selectedItem = null;

      result.tree = root;
      return result;
    }

    // loaded menu
    if (typ.children) {
      let item = typ.children.find((mn) => mn.path === itemPath);
      if (!item) {
        item = MenuNode.findDeepNode(typ.children, itemSearch, hwTypePath, group);
      }
      if (item) {
        if (GLOB.selectedClass !== clsPath || !allType && GLOB.selectedType !== typeOrDevicePath
          || allType && (!GLOB.selectedAllType || allType !== GLOB.selectedAllType)) {
          GLOB.selectedClass = clsPath;
          GLOB.selectedType = typeOrDevice ? typeOrDevicePath : null;
          result.tree = root;
        }
        GLOB.selectedGroup = group;
        GLOB.selectedItem = item;
        GLOB.selectedAllType = allType;
        if (item.component || item.url)
          result.item = item;
        return result;
      }
    } else {
      //typ.children = []; or modify if in leftTree
    }

    // load from server
    let item: MenuNode;
    let tree = clsPath === GLOB.selectedClass && GLOB.selectedAllType === allType ? GLOB.getTree() : cls;

    if (tree && GLOB.selectedClass === clsPath && (!allType && GLOB.selectedType === typeOrDevicePath || allType && GLOB.selectedAllType)) {
      item = MenuNode.findDeepNode(tree.children, itemSearch, hwTypePath, group);
    } else {
      tree = root;
    }
    let reload = false;
    if (!item) {
      // let typePath = typeOrDevicePath;
      // if (isDevicesTree(allType)) {
      //   const dev = GLOB.menuService.storagesBy.device.children.find(mn => mn.title === typeOrDevice);
      //   typePath = dev ? '/' + dev.hwType : typeOrDevicePath;
      // } else if (GLOB.selectedAllType === RoutePath.GROUP) {
      //   typePath = null;
      // }
      const urlGetPath = getPathUrl(clsPath, allType, groupPath, typeOrDevicePath, itemSearch);
      await axios.get<MenuResponseDTO | DefaultDashboardDTO>(urlGetPath, { signal: abortSignal }).then(
        (response) => {
          if (abortSignal.aborted) return;
          let itemToSearch = itemSearch;
          let data = response.data.data;
          if (!data) {
            //Log.error('No page for '+itemSearch);
            return;
          }
          if (!Array.isArray(data)) {
            itemToSearch = '/' + data.dashboard.dashboard_id;
            data = data.path;
          }
          if (!tree.children) tree.children = [];
          let rootTree = tree;
          if (allType && clsName !== ClassEnum.SAN && clsName !== ClassEnum.LAN) {
            if (allType === RoutePath.TYPE) {
              rootTree = tree.children.find(mn => mn.path === typeOrDevicePath);
            } else if (allType === RoutePath.GROUP) {
              //rootTree = groupNode;
            } else {
              //rootTree = tree.children.find(mn => mn.title === typeOrDevice);
            }
            if (!rootTree.children) rootTree.children = [];
          }

          if (mergeTree(rootTree.children, data, group)) {
            mapParent(rootTree.children, rootTree);
            tree.children = [...tree.children];
          }
          item = MenuNode.findDeepNode(tree.children, itemToSearch, hwTypePath, group);
          tree = { ...tree };
          if (!item) {
            Log.error('Cannot find item in merged tree!', response);
          }
          else {
            const deviceNode = getHwTypeParent(item);
            const devicePath = deviceNode ? '/' + deviceNode.titleText : '';
            if (GLOB.selectedClass && isDevicesTree(allType || GLOB.selectedAllType)
              && (typeOrDevicePath && devicePath != typeOrDevicePath || allType && allType !== GLOB.selectedAllType)
              && (!rootTree.children.some(mn => mn.title === typeOrDevice) && allType !== RoutePath.GROUP ||
                !rootTree.children.some(mn => mn.children?.some(c => c.title === typeOrDevice)) && allType === RoutePath.GROUP)) {
              reload = true;
            }
          }
        },
        (reason) => {
          if (abortSignal.aborted) return;
          Log.error('Failed to get menu path!', reason);
        }
      );
      if (abortSignal.aborted) return;
    } else {
      if (clsPath !== GLOB.selectedClass) {
        result.tree = tree;
      }
      GLOB.selectedAllType = allType;
      GLOB.selectedClass = clsPath;
      GLOB.selectedGroup = group;
      GLOB.selectedType = typeOrDevicePath;
    }

    if (!item) {
      Log.warn('Navigation failed, no item ' + itemSearch + ' in tree!');
    } else {
      if (reload) {
        const deviceNode = getHwTypeParent(item);
        const devicePath = deviceNode ? '/' + deviceNode.titleText : '';
        const parentNode = getTopParent(item);
        if (devicePath !== typeOrDevicePath || parentNode !== deviceNode && deviceNode) {
          result.nav = {
            to:
              [GLOB.selectedClass, GLOB.selectedAllType, GLOB.selectedGroup ? '/' + GLOB.selectedGroup : null, devicePath, itemSearch + itemHash]
                .filter(s => s).join(''), options: { replace: true }
          };
          result.tree = tree;
          return result;
        }
      }
      if (clsPath !== GLOB.selectedClass) {
        result.tree = tree;
      }
      GLOB.selectedAllType = allType;
      GLOB.selectedClass = clsPath;
      GLOB.selectedGroup = group;
      GLOB.selectedType = typeOrDevicePath;
      if (item !== GLOB.selectedItem) {
        result.tree = tree;
        GLOB.selectedItem = item;
      }

      result.item = item;
      return result;
    }
  }

  render(): ReactNode {
    return <>
      {this.state.loading ? <Spinner />
        : this.state.navItem && (this.state.navItem?.component ? this.state.navItem.component
          : (this.state.navItem.isLeaf && <BasePage url={this.state.navItem.url} />))}
    </>;
  }
}

export default appGlobConnector(withRouter(NavigateRoute));
