import { configureStore } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { alertSlice } from '../content/monitoring/alerting/alertStore';
import { deviceSlice } from '../content/monitoring/device/stor/deviceStore';
import { appGlobalSlice } from './appGlobalStore';

export const store = configureStore({
  reducer: {
    appGlobals: appGlobalSlice.reducer,
    devices: deviceSlice.reducer,
    alerts: alertSlice.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type
export type AppDispatch = typeof store.dispatch

const mapAppGlobState = (state: RootState) => ({ ...state.appGlobals });
export const appGlobConnector = connect(mapAppGlobState, appGlobalSlice.actions);
export type AppGlobProps = ConnectedProps<typeof appGlobConnector>;
