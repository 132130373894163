import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash-es';
import { Key } from 'react';
import { AlertGroupTableData } from './AlertingTable';

export class AlertTab {
  key: string;
  label: string;
  class: string;
  alertGroups: AlertGroupTableData[];
  expandRowKey: Key;
  touched?: boolean;
}

const alertTabs: AlertTab[] = [];

function removeGroup(state: AlertTab[], data: PayloadAction<{ tabIndex: number; groupIndex: number; }>) {
  const tab = state[data.payload.tabIndex];
  const groups = tab.alertGroups;
  groups.splice(data.payload.groupIndex, 1);
  tab.touched = true;
}

export const alertSlice = createSlice({
  name: 'alerts',
  initialState: alertTabs,
  reducers: {
    addTab: (state, tab: PayloadAction<AlertTab>) => {
      tab.payload.touched = true;
      state.push(tab.payload);
    },
    removeTab: (state, id: PayloadAction<string>) => {
      for (let index = 0; index < state.length; index++) {
        if (state[index].key === id.payload) {
          state.splice(index, 1);
          break;
        }
      }
    },
    setTouched: (state, data: PayloadAction<{ tabIndex: number, touched: boolean }>) => {
      state[data.payload.tabIndex].touched = data.payload.touched;
    },
    setRowKeyToExpand: (state, data: PayloadAction<{ tabIndex: number, key: Key }>) => {
      state[data.payload.tabIndex].expandRowKey = data.payload.key;
    },
    addGroup: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData }>) => {
      const tab = state[data.payload.tabIndex];
      tab.alertGroups.push(data.payload.group);
      tab.touched = true;
    },
    removeGroup,
    setGroup: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData }>) => {
      const tab = state[data.payload.tabIndex];
      const groupIndex = tab.alertGroups.findIndex(g => g.key === data.payload.group.key);
      tab.alertGroups[groupIndex] = data.payload.group;
      tab.touched = true;
    },
    mergeGroup: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData }>) => {
      const p = data.payload;
      const tab = state[p.tabIndex];
      const groupIndex = tab.alertGroups.findIndex(g => g.key === p.group.key);
      const grp = { metrics: [], items: [], alerts: [] } as AlertGroupTableData;
      _.merge(grp, tab.alertGroups[groupIndex], p.group);
      grp.items = [...grp.items];
      tab.alertGroups[groupIndex] = data.payload.group;
      tab.touched = true;
    },
    mergeGroups: (state, { payload: { tabIndex, groups, changed } }: PayloadAction<{ tabIndex: number, groups: AlertGroupTableData[], changed: boolean }>) => {
      const tab = state[tabIndex];
      _.merge(tab.alertGroups, groups);
      // * _.merge from lodash is not right choice for changing values in multiselect ant.design comp
      tab.alertGroups.forEach((group, gIndex) => group.alerts.forEach((alert, aIndex) => { alert.emailGroups = groups[gIndex].alerts[aIndex].emailGroups }));
      tab.alertGroups = [...tab.alertGroups];
      tab.touched = tab.touched || changed;
    },
    removeAlert: (state, data: PayloadAction<{ tabIndex: number, group: AlertGroupTableData, alertIndex: number }>) => {
      const tab = state[data.payload.tabIndex];
      const groupIndex = tab.alertGroups.findIndex(g => g.label === data.payload.group.label);
      const group = state[data.payload.tabIndex].alertGroups[groupIndex];
      if (group.alerts.length < 2) {
        removeGroup(state, { payload: { tabIndex: data.payload.tabIndex, groupIndex }, type: null });
      } else {
        group.alerts.splice(data.payload.alertIndex, 1);
      }
      tab.touched = true;
    },
    replaceGroups: (state, data: PayloadAction<{ tabIndex: number, groups: AlertGroupTableData[] }>) => {
      const tab = state[data.payload.tabIndex];
      tab.alertGroups = [...data.payload.groups];
      tab.touched = false;
    },
    replaceStore: (state, replacement: PayloadAction<AlertTab[]>) => {
      return replacement.payload;
    }
  },
});