import { DatePicker } from "antd"
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker"
import moment, { Moment } from "moment"
import { FC } from "react"

const { RangePicker } = DatePicker

const RangeDatePicker: FC<RangePickerDateProps<Moment>> = (props) => {
  return (
    <RangePicker
      ranges={{
        'Last Hour': [moment().subtract(1, 'hour'), moment()],
        'Last Day': [
          moment().subtract(1, 'day').startOf('day'),
          moment().subtract(1, 'day').endOf('day'),
        ],
        'Last Week': [
          moment().subtract(1, 'week').startOf('day'),
          moment().endOf('day'),
        ],
        'Last Year': [
          moment().subtract(1, 'year').startOf('day'),
          moment().endOf('day'),
        ],
      }}
      showTime={{ format: 'HH:mm' }}
      format="YYYY/MM/DD HH:mm"
      {...props}
    />
  )
}

export default RangeDatePicker