import { RegexPreviewDTO } from "@dto/architecture.dto";
import { isClassLPAR } from "src/data/Class";
import { RuleGroup } from "../../RulesList";
import { UsePreviewModal } from "./Preview";

type CgPreviewHandlerType = (
  cls: string,
  hwType: string,
  subsystem: string,
  showModal: ReturnType<UsePreviewModal>[0]
) => (rules: RuleGroup[]) => () => void;

const cgPreviewHandler: CgPreviewHandlerType = (cls, hwType, subsystem, showModal) => (rules) => () => {
  const includeIds: string[] = [];
  const regexNew: RegexPreviewDTO[] = [];

  rules.forEach(({ items, tags, regex, parents }) => {
    if (items) {
      includeIds.push(...items.filter(item => !item.disabled && !includeIds.includes(item.item_id)).map((item) => item.item_id))
      return;
    }

    if (tags) {
      // TODO: add tags part
      return;
    }

    if (regex.length)
      regexNew.push(makeRegexApiData(cls, hwType, subsystem, regex, parents));
  });

  showModal({
    cls: cls,
    hw_type: hwType,
    subsystem,
    data: regexNew,
    includeIds: includeIds.length ? includeIds : undefined
  });
};

export function makeRegexApiData(cls: string, hwType: string, subsystem: string, regex: string[], parents: RuleGroup['parents'], linuxExclude = false): RegexPreviewDTO {
  return {
    class: cls,
    hw_type: isClassLPAR(cls) ? hwType : undefined,
    subsystem,
    regex,
    linuxExclude,
    parents: parents && {
      intersection: parents.intersection,
      regex: parents.regex?.filter(parent => !!parent.subsystem),
      tags: parents.tags?.map(tag => tag.tag_id),
      item_ids: parents.items?.map(item => item.item_id),
    }
  };
}

export default cgPreviewHandler;