import { AlertsResponseDTO, CreateAlertGroupDTO } from '@dto/alerting.dto';
import { ClassesResponseDTO, MetricsResponseDTO, SubsystemsResponseDTO } from '@dto/architecture.dto';
import { BasicResponseDTO, TextResponseDTO } from '@dto/basicResponse.dto';
import { CustomGroupItemDTO, CustomGroupsResponseDTO } from '@dto/customGroup.dto';
import axios, { AxiosResponse } from 'axios';
import { postApi } from 'src/util/apiCalls';
import { API_URL } from '../../../data/Api';
import { Log } from '../../../service/Log';

//TODO: make separate glob promise
export function getAllSubsystems(type?: string) {
  const promise = axios.get<SubsystemsResponseDTO>(type ? API_URL.ARCHITECTURE + `/subsystems?type=${type}` : API_URL.ARCHITECTURE + `/subsystems`);
  promise.catch(reason => Log.error('Failed to get subsystems!', reason));
  return promise;
}

export async function getCustomGroups() {
  const promise = axios.get<CustomGroupsResponseDTO>("/api/custom_group/v1/custom_groups");
  promise.catch(reason => Log.error('Failed to get custom groups!', reason));
  return promise;
}

export async function getCustomGroupItems(id: string) {
  const promise = axios.get<CustomGroupItemDTO>(API_URL.CUSTOM_GROUP + '/custom_groups/' + id + '/items');
  promise.catch(reason => Log.error('Failed to get items of custom group ' + id, reason));
  return promise;
}

export async function getClasses() {
  const promise = axios.get<ClassesResponseDTO>(API_URL.ARCHITECTURE + '/classes?active=true');
  promise.catch(reason => Log.error('Failed to get classes!', reason));
  return promise;
}

// export async function getAllMetrics() {
//   const promise = axios.get<ArrayOfObjectResponseDTO>(API_URL.ARCHITECTURE + '/metrics');
//   promise.catch(reason => Log.error('Failed to get metrics!', reason));
//   return promise;
// }

export async function getMetricsCg(id: string) {
  const promise = axios.get<MetricsResponseDTO>("/api/custom_group/v1/custom_groups/" + id + "/metrics");
  promise.catch(reason => Log.error('Failed to get CG metrics!', reason));
  return promise;
}

export async function postAlertGroups(postData: CreateAlertGroupDTO[]) {
  const promise = postApi<AlertsResponseDTO, CreateAlertGroupDTO[]>("/api/alerting/v1/alertGroups", postData);
  promise.catch(reason => Log.error('Failed to create new alert', reason));
  return promise;
}

export function getAlertGroups() {
  const promise = axios.get<AlertsResponseDTO>(API_URL.ALERTING + '/alertGroups');
  promise.catch(reason => Log.error('Failed to get alert groups!', reason));
  return promise;
}

export function deleteAlertGroups(alertGroupIds: string[]) {
  const promise = axios.delete<string[], AxiosResponse<BasicResponseDTO>>(API_URL.ALERTING + '/alertGroups', { data: alertGroupIds });
  promise.catch(reason => Log.error('Failed to delete alert groups!', reason));
  return promise;
}

export async function getLogs(lines: number) {
  const promise = axios.get<TextResponseDTO>("/api/log/alerting?lines=" + lines);
  promise.catch(reason => Log.error('Failed to get alerting logs!', reason));
  return promise;
}

