import { Item, ItemsResponseDTO, RegexPreviewDTO, SubsystemsResponseDTO } from '@dto/architecture.dto';
import { Modal } from 'antd';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { ColProps, GridScope, IGridEntry } from "src/component/grid/Grid";
import { getAllSubsystems } from 'src/content/monitoring/alerting/alertingService';
import { getLabel } from 'src/content/search/useSearch';
import { API_URL } from 'src/data/Api';
import { Log } from 'src/service/Log';
import { postApi } from 'src/util/apiCalls';
import AllItems, { AllItemsContext } from '../AllItems';
import './Preview.less';

export type UsePreviewModal = (linuxExclude?: boolean, fetchAllItems?: boolean) => [(previewProps: Omit<Props, 'linuxExclude'>) => void, React.ReactElement];
export const usePreviewModal: UsePreviewModal = (linuxExclude = false, fetchAllItems = false) => {
  const [modal, contextHolder] = Modal.useModal();

  function showModal(previewProps: Omit<Props, 'linuxExclude'>) {
    const Content = fetchAllItems ? AllItemsPreview : Preview;

    modal.info({
      className: 'xm-preview',
      icon: null,
      okButtonProps: { hidden: true },
      okText: 'Close',
      maskClosable: true,
      width: 'auto',
      centered: true,
      closable: true,
      content: <Content {...previewProps} linuxExclude={linuxExclude} />
    });
  }

  return [showModal, contextHolder];
}

const AllItemsPreview = (props: Props) => (
  <AllItems cls={props.cls} hw_type={props.hw_type} subsystem={props.subsystem} noTagMode linuxExclude={props.linuxExclude}>
    <Preview {...props} />
  </AllItems>
);

class ItemParentLabel extends Item {
  parentLabel: string
}

type Dto = RegexPreviewDTO | RegexPreviewDTO[];

interface Props {
  cls: string;
  hw_type: string;
  subsystem: string;
  data: Dto;
  includeIds?: string[] | undefined;
  parent?: boolean;
  linuxExclude: RegexPreviewDTO['linuxExclude'];
  strictAcl?: boolean;
}

function Preview({ data, parent, includeIds, strictAcl, ...rest }: Props) {
  const [gs] = useState(() => new GridScope<ItemParentLabel>())
  const [items, setItems] = useState<ItemParentLabel[]>([])
  const [loading, setLoading] = useState(true);
  const { promise } = useContext(AllItemsContext);
  const subsystemLabel = useRef(rest.subsystem);

  useEffect(() => {
    if (promise === undefined)
      return;

    const controller = new AbortController();
    setLoading(true);
    function makeItems([items, regex, subsLabels]: [AxiosResponse<ItemsResponseDTO, Dto>, AxiosResponse<ItemsResponseDTO, RegexPreviewDTO>, AxiosResponse<SubsystemsResponseDTO>]) {
      const newItems: ItemParentLabel[] = [];
      function makeItemLabel(item: Item): ItemParentLabel {
        return {
          ...item,
          parentLabel: item.parents?.map((p) => p.label).sort().join(', '),
        };
      }

      subsystemLabel.current = getLabel('subsystem', subsLabels.data.data)(rest.subsystem, ({ hw_type }) => (rest.hw_type === hw_type));
      if (includeIds)
        newItems.push(...regex.data.data.filter(({ item_id }) => includeIds.includes(item_id)).map(makeItemLabel));

      newItems.push(...items.data.data.filter(({ item_id }) => !includeIds?.includes(item_id)).map(makeItemLabel));
      setItems(newItems.sort((a, b) => (a.parentLabel + a.label).localeCompare(b.parentLabel + b.label)));
    }

    function getRegex(call: ApiCall<Dto>) {
      Promise.all([call(data, { signal: controller.signal }, strictAcl), promise, getAllSubsystems()]).then(
        makeItems,
        reason => Log.error('Failed to get preview!', reason)
      ).finally(() => setLoading(false));
    }

    if (isNotMultiRegex(data))
      getRegex(regexApi);
    else
      getRegex(multiRegexApi);

    return () => controller.abort();
  }, [promise]);

  const columns: ColProps<ItemParentLabel & IGridEntry>[] = parent ? [
    {
      className: 'xm-col',
      title: 'Parent',
      dataIndex: 'label',
      sorter: (l, r) => l.label.localeCompare(r.label),
      filter: { field: ['label'] },
    }
  ] : [
    {
      className: 'xm-col',
      title: 'Parents',
      dataIndex: 'parentLabel',
      sorter: (l, r) => l.parentLabel.localeCompare(r.parentLabel),
      filter: { field: ['parentLabel'] },
    },
    {
      className: 'xm-col',
      //minWidth: GLOB.measureText(subsystemLabel.current, 'min-content') + GridScope.TH_EXTRA_WIDTH,
      title: subsystemLabel.current,
      dataIndex: 'label',
      sorter: (l, r) => l.label.localeCompare(r.label),
      filter: { field: ['label'] },
    },
  ];

  return (
    <gs.Grid
      className='xm-table-fit'
      dataSource={items}
      pagination={false}
      scroll={{ x: true, y: '80vh' }}
      rowKey={(r) => r.item_id}
      loading={loading}
      columns={columns}
    />
  )
}

function isNotMultiRegex(data: RegexPreviewDTO | RegexPreviewDTO[]): data is RegexPreviewDTO {
  return (data as RegexPreviewDTO[]).length === undefined;
}

type ApiCall<T> = (data: T, config?: AxiosRequestConfig<T>, strictAcl?: boolean) => Promise<AxiosResponse<ItemsResponseDTO, T>>;
export const regexApi: ApiCall<RegexPreviewDTO> = (data, config, strictAcl = true) => postApi<ItemsResponseDTO, RegexPreviewDTO>(API_URL.ARCHITECTURE + `/regex?parents=true&strict_acl=${strictAcl ? 'true' : 'false'}`, data, config);
export const multiRegexApi: ApiCall<RegexPreviewDTO[]> = (data, config, strictAcl) => postApi<ItemsResponseDTO, RegexPreviewDTO[]>(API_URL.ARCHITECTURE + `/multiregex?parents=true&strict_acl=${strictAcl ? 'true' : 'false'}`, data, config);
