import { BackendResponseDTO } from "@dto/backendResponse.dto";
import axios from "axios";
import { API_URL } from "src/data/Api";
import { GLOB } from "src/util/Glob";
import { Log } from "./Log";

export class DataService {
  //TODO: preload classes, hwtypes...
  // //TODO: only 1 websocket in app! reconnect management
  //static readonly socket: Socket<WsEventsFromServer> = io(`${GLOB.SERVER_WS_URL}/websocket/reload/`, { transports: ['websocket', 'webtransport', 'polling'] });
  //static readonly ws = new WebSocket(GLOB.SERVER_WS_URL + '/websocket/reload');

  static {
    // DataService.socket.on('connect', () => console.log('socket connected'));
    // DataService.socket.on('connect_error', err => console.log('socket error', err));
  }

  /**
   * Checks if backend is on different version than UI, reloads page if so
   * @returns 'reload' if reloading, 'error' on fail, undefined otherwise
   */
  static checkVersionReload(): Promise<'reload' | 'error'> {
    const preVer = GLOB.getBackendInfo?.()?.backend.version;
    GLOB.infoLoaded.reset();
    return axios.get<BackendResponseDTO>(API_URL.INFO).then((response) => {
      const dto = response.data.data;
      GLOB.setBackendInfo(dto);
      GLOB.infoLoaded.resolve();
      if (preVer && dto.backend.version !== preVer) {
        DataService.reloadUI();
        return 'reload';
      }
    }, reason => {
      Log.error('Failed to get app info!', reason);
      GLOB.infoLoaded.reject(reason);
      return 'error';
    });
  }

  static reloadUI() {
    Log.warn('You are on different version of Xormon, reloading...');
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  // constructor(){
  //   this.ws.onopen = () => setIsConnected(true);
  //   this.ws.onclose = (event) => {
  //     // TODO: to use this, it is necessary to sync BE&FE usage of WS
  //     // if (!event.wasClean && event.reason)
  //     //   Log.error(`Close of WebSocket '${url}' was unexpected.`, event.reason);

  //     setIsConnected(false);
  //   }
  //   this.ws.onmessage = (event) => setData(JSON.parse(event.data));
  // }

}