import { HistoryOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { RecentlySearchedResponseDTO } from "@dto/recentlySearched.dto";
import { SearchItemResponseDTO } from "@dto/searchResponse.dto";
import { TagsResponseDTO } from "@dto/tag.dto";
import { AutoComplete, Button, Input, InputRef, Tooltip } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ChangeEvent, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from 'src/hook/useDebounce';
import { Log } from 'src/service/Log';
import 'src/styles/_variables.less';
import { GLOB } from "src/util/Glob";
import { Action, Options, useSearchReducer } from "../reducer/useSearchReducer";

const SearchBar = () => {
  const { state, dispatch } = useSearchReducer();
  const { keepInput, onIcon, search, options, onSearch } = state;
  const location = useLocation();
  const { pathname, search: searchByLocation } = location;
  const [prevLocation, setPrevLocation] = useState(location);
  const navigate = useNavigate();
  const inputR = useRef<InputRef>();
  const iconR = useRef<HTMLDivElement>();
  const inputWrapper = useRef<HTMLDivElement>();
  const isTag = search.startsWith('tag:');
  const isRegex = search.startsWith('re:');
  const search4debounce = isTag ? search.replace(/^tag\:/, '') : isRegex ? '' : search;
  const debouncedSearch = useDebounce<string>(search4debounce);

  if (location !== prevLocation) { // reset searched text on URL change
    setPrevLocation(location);
    // dispatch({ type: Action.resetText });
  }

  useEffect(() => {
    void getLastSearch()
  }, [])

  const useQuery = (): [string, URLSearchParams] => useMemo(() => [pathname, new URLSearchParams(searchByLocation)], [searchByLocation]);

  const [path, query] = useQuery();
  const searchTitle = query.has("find") && query.get("find");
  useEffect(() => {
    if (searchTitle) {
      dispatch({ type: Action.type, payload: searchTitle });
    }
  }, [searchTitle])

  const getLastSearch = async () => {
    const response = await axios.get<RecentlySearchedResponseDTO>(`/api/search/v1/recently_searched?limit=1&user_id=${GLOB.userInfo.user_id}`)
    const { data } = response.data
    if (data?.length > 0) {
      dispatch({ type: Action.type, payload: data[0]?.search_query })
    }
  }

  useEffect(() => {
    if (keepInput)
      inputR.current?.focus();
  }, [keepInput]);

  useEffect(() => {
    if (!debouncedSearch || debouncedSearch.length <= 2) // search option only on text with length at least 3 characters
      return;

    const controller = new AbortController();
    const setOptions = (payload: Options[] = []) => dispatch({ type: Action.setOpt, payload });

    const onResolve = ({ data: { data } }: AxiosResponse<SearchItemResponseDTO | TagsResponseDTO>) => {
      if (!Array.isArray(data)) {
        if (!data.items) {
          setOptions();
          return;
        }

        setOptions(data.items.map(item => ({
          value: item.label,
          key: item.item_id,
          link: (item.class) ? GLOB.menuService.getRouteLink(item.url) : "alert"
        })))

        return;
      }

      // Tag search
      const opt: Options[] = data.map(({ tag_id: key, tag: value }) => ({
        value,
        key,
        link: `tag_id=${key}&tag_name=${value}`
      }));

      setOptions(opt);
    };

    const api = isTag ? `/api/tag/v1/search/${debouncedSearch}` : `/api/search/v1/item/${encodeURIComponent(debouncedSearch)}?page=0&limit=10`;
    axios.get<SearchItemResponseDTO>(api, { signal: controller.signal }).then(
      onResolve,
      () => dispatch({ type: Action.setOpt, payload: [] })
    );

    return () => controller.abort();
  }, [debouncedSearch]);

  const goToPage = (link?: string) => {
    if (link === 'alert') {
      Log.info(`Item is missing class ${search}.`);
      return;
    }

    const path = isTag ? `/search?${link}` : link || `/search?find=${encodeURIComponent(search)}`;
    navigate(path);
    dispatch({ type: Action.redirect });
  }

  const handlePressEnter = async () => {
    if (isTag) {
      options.length == 1 && goToPage(options[0].link);
      return;
    }

    if (GLOB?.userInfo?.user_id && search?.trim().length > 0) {
      try {
        await axios.post(`/api/search/v1/recently_searched/add?user_id=${GLOB?.userInfo?.user_id}&search_query=${encodeURIComponent(search)}`)
        options.length == 1 ? goToPage(options[0].link) : goToPage();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<BasicResponseDTO>
          Log.error(axiosError.response.data.message, axiosError)
        } else {
          Log.error("API Error", error)
        }
      }
    } else {
      dispatch({ type: Action.type, payload: '' });
    }
  }

  const handleSelect = (_: string, opt: Options) => goToPage(opt.link);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => dispatch({ type: Action.type, payload: e.target.value });
  const handleClickIn = () => {
    if (isTag) {
      options.length == 1 && goToPage(options[0].link);
      return;
    }

    goToPage();
  }

  const handleClickOut: React.FocusEventHandler<HTMLDivElement> = ({ relatedTarget }) => {
    if (relatedTarget && inputWrapper.current.contains(relatedTarget) || onIcon)
      return;

    if (relatedTarget?.id === "help-regex-link") {
      setTimeout(() => {
        dispatch({ type: Action.redirect });
      }, 100);

      return;
    }

    dispatch({ type: Action.hide });
  };

  const handleClickIcon = () => {
    if (isTag && options.length == 1)
      goToPage(options[0].link);

    dispatch({ type: Action.keepInput });
    dispatch({ type: Action.onIcon });
  };

  if (!keepInput) {
    return <>
      <div className="search-input" />
      <SearchIcon
        className="xm-search-icon"
        onClick={handleClickIcon}
        ref={iconR}
      />
    </>;
  }

  const handleFocusInput = () => inputR.current?.focus();

  const handleSearchHistory = () => {
    const path = `/search`;
    navigate(path);
    // dispatch({ type: Action.redirect });
    // dispatch({ type: Action.type, payload: '' });
  };

  return <>
    <AutoComplete
      popupClassName="whisper"
      onSelect={handleSelect}
      options={options}
      dropdownStyle={{ marginTop: (iconR.current.offsetHeight + 6) + "px" }}
      className="xm-search-open-wrapper"
    >
      <div className="search-content-wrapper" tabIndex={1} onClick={handleFocusInput}>
        <div className="search-input"
          ref={inputWrapper}
          tabIndex={1}>
          <Input
            onBlur={handleClickOut}
            onChange={handleChange}
            onPressEnter={handlePressEnter}
            ref={inputR}
            placeholder="Search..."
            value={search}
            addonBefore={<Button onClick={handleSearchHistory} type="link" icon={<HistoryOutlined />} style={{ height: 'auto' }} />}
            addonAfter={
              <Tooltip overlayStyle={{ maxWidth: '500px' }} placement={'bottomRight'} mouseLeaveDelay={0.5} title={<span>
                Search options<br />
                1) Text: start typing searched string (e.g. "vm01")<br />
                2) <a id="help-regex-link" href='https://xormon.com/regular_expression.php' target="_blank">Regular expression</a>: type "re:" followed by regular expression (e.g. "re:^vm[0-9]+$")
              </span>}>
                <QuestionCircleOutlined className={'xm-search-help'} />
              </Tooltip>
            }
          />
        </div>
        <SearchIcon key='search-show'
          className={(keepInput || onSearch) ? "xm-search-icon xm-search-icon-active" : "xm-search-icon"}
          onClick={handleClickIn}
          onMouseLeave={() => dispatch({ type: Action.onIcon, payload: false })}
          onMouseEnter={() => dispatch({ type: Action.onIcon, payload: true })}
          ref={iconR}
        />
      </div>
    </AutoComplete>
  </>;
}

const SearchIcon = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(function SearchIcon(props, ref) {
  return <div {...props} ref={ref}>
    <SearchOutlined />
  </div>
});

export default SearchBar;