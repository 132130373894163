import { GRAPH_INTERVAL } from '@const/pageResponse.constants';
import { TIMESERIES_TOP_ORDER } from '@const/timeseriesRequest.contants';
import { IMetric } from '@dto/architecture.dto';
import { ExportTimeseriesRequestDTO } from '@dto/exporter.dto';
import { Metric } from '@dto/pageResponse.dto';
import { TimeseriesRequestDTO, TimeseriesTopOrder } from '@dto/timeseriesRequest.dto';
import { TimeseriesResponseDTO } from '@dto/timeseriesResponse.dto';
import { RegroupContext } from '@parts/graph/RegroupedGraphPart';
import { MenuProps, Modal, Spin } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { Config, Icon, ModeBarButton, PlotData, PlotRelayoutEvent } from 'plotly.js';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import { FaAngleDoubleDown, FaExpand, FaStar } from 'react-icons/fa';
import { MdLineAxis } from 'react-icons/md';
import Plot from 'react-plotly.js';
import { useResizeDetector } from 'react-resize-detector';
import { useSearchParams } from 'react-router-dom';
import { API_URL } from 'src/data/Api';
import { MenuNode } from 'src/model/MenuNode';
import { Log } from 'src/service/Log';
import { DateUtil } from 'src/util/DateUtil';
import { GLOB } from 'src/util/Glob';
import { postApi } from 'src/util/apiCalls';
import { saveBlobToFile } from '../apiDownloader/ApiDownloader';
import { Spinner } from '../spinner/Spinner';
import './Graph.less';
import { GraphBtns } from './GraphBtns';
import { GraphPlotUtil } from './GraphPlotUtil';
import { GraphTable } from './GraphTable';
import { GraphProps, GraphState, GraphType, KTM, PlotCmp, createPlotlyLayout, createYaxis } from './GraphTypes';
import GraphDashboardSelect from './modal/GraphDashboardSelect';
import { TopItemsModal } from './modal/TopItemsModal';
import { YaxisModal } from './modal/YaxisModal';

const X_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

let minZoomNotified = false;

/**
 * Default minimum zoom interval in ms is 1 hour
 */
const MIN_ZOOM_INTERVAL = 1000 * 60 * 60;

const BTN_NAME_LOAD_ALL = 'loadAll';

function getTopItems(): number {
  return GLOB.userInfo.configuration.top ?? GLOB.graphTopItems;
}

function convertIconToPlotly(iconType: IconType): Icon {
  const iconProps = iconType(null).props;
  const iconViewBox = iconProps.attr.viewBox.split(' ');
  return { name: iconType.name, height: iconViewBox[3], width: iconViewBox[2], path: iconProps.children[iconProps.children.length - 1].props.d };
}

function getFullScreenTitle(props: GraphProps, graphName: string) {
  if (props.type === GraphType.dashboard)
    return graphName;

  const path = MenuNode.getParentNodes(GLOB.selectedItem).map(mn => mn.titleText);
  path.shift();
  path.push(props.common?.tabName, graphName);
  return path.join(' : ');
}

export const Graph: React.FC<GraphProps> = (props) => {
  const { box, type } = props;
  const [modalFullscreenVisible, setModalFullscreenVisible] = useState(false);
  const [modalOtherOpen, setModalOtherOpen] = useState(false);
  const [modalAxisOpen, setModalAxisOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<PlotData>[]>([]);
  const [tableData, setTableData] = useState<KTM[]>([]);
  const [zooming, setZooming] = useState(false);
  const [plotVisible, setPlotVisible] = useState(true);
  const [searchParams] = useSearchParams();
  const regroupedProps = useContext(RegroupContext);
  const aborter = useRef<AbortController>();

  const [state] = useState<GraphState>(() => props.state ||
    ({
      additionalYaxis: null,
      order: box.sort?.order || TIMESERIES_TOP_ORDER.avg, sortMetric: box.sort?.metric,
      loadedItems: GraphPlotUtil.isOthersAllowed(box) ? getTopItems() : 0,
      othersTooltip: null, othersCount: null, uuids: null, hoverMode: 'closest', status: 'none',
      tableRowKeys: [], plotlyRef: null, unitSize: '', startSec: props.start, endSec: props.end
    }));
  const [graphUtil] = useState(() => new GraphPlotUtil(box, props.common, props.data, state));

  const [dashSelectTimestamp, setDashSelectTimestamp] = useState(0);

  const [propsFS, setPropsFS] = useState(props);
  const [onClick] = useState(() => {
    let handler: React.MouseEventHandler<HTMLDivElement>;
    if (type === GraphType.dashboard) {
      handler = (event) => {
        openFullscreen();
      }
    }
    return handler;
  });

  const [dropdownItems] = useState<MenuProps>(() => {
    const items: ItemType[] = [];

    items.push({
      key: 'csv',
      label: 'CSV',
      title: 'Download CSV',
      onClick: e => {
        if (!GLOB.isValidEnterprise()) {
          Log.warn(<div style={{ width: '38em' }}>Export to CSV is available in the <strong>Enterprise Edition</strong> of Xormon NG.
            <br />For more information, please visit our website <a href='https://xormon.com/support-ng.php' target="_blank">https://xormon.com/support-ng.php</a></div>);
          return;
        }
        axios.post<Blob, AxiosResponse<Blob>, ExportTimeseriesRequestDTO>('/api/exporter/v1/timeseries',
          {
            start: state.startSec,
            end: state.endSec,
            uuids: box.uuids,
            metric: box.metrics.map(m => m.metric)
          },
          {
            responseType: 'blob', signal: aborter.current.signal,
            params: {
              format: 'csv'
            }
          }).then(response => {
            void saveBlobToFile(response.data, getFileName() + '.csv');
          }, reason => Log.error('Failed to export plot to CSV!', reason));
      }
    });

    if (isPeakMetricAllowed()) {
      const style: React.CSSProperties = { backgroundColor: undefined };
      items.push({
        key: 'max',
        label: 'MAX',
        title: 'Toggle peak metric',
        style: style,
        onClick: e => {
          state.peakMetric = !state.peakMetric;
          if (state.peakMetric) style.backgroundColor = "#def";
          else style.backgroundColor = undefined;
          requestData(state.startSec, state.endSec);
        }
      });
    }

    if (type === GraphType.regular && props.interval) {
      items.push({
        key: 'fullscreen',
        icon: <FaExpand />,
        title: 'Fullscreen',
        onClick: e => openFullscreen()
      })
    };

    if (GraphPlotUtil.isOthersAllowed(box)) {
      items.push({
        key: BTN_NAME_LOAD_ALL,
        icon: <FaAngleDoubleDown />,
        title: 'Load top items',
        onClick: e => setModalOtherOpen(true)
      })
    }

    if (props.common?.availableMetrics?.length && graphUtil.isMetricItems()) {
      items.push({
        key: 'secondyaxis',
        title: 'Additional Y-Axis',
        icon: <MdLineAxis />,
        onClick: e => {
          setModalAxisOpen(true);
        }
      });
    }

    return { items };
  });

  const [config] = useState(() => {
    const modeBarButtonsToAdd: ModeBarButton[] = [];

    if (type === GraphType.regular && props.interval && GLOB.selectedItem.titleText?.toLowerCase() !== 'view') {
      modeBarButtonsToAdd.push({
        name: 'favorite',
        attr: 'favorite',
        title: 'Manage in dashboards',
        icon: convertIconToPlotly(FaStar),
        click: (gd, ev) => setDashSelectTimestamp(Date.now())
      });
    }
    const cfg: Partial<Config> = {
      displaylogo: false, autosizable: true, responsive: true, displayModeBar: true,
      modeBarButtons: [['resetScale2d'], modeBarButtonsToAdd],
      toImageButtonOptions: { filename: getFileName() }
    }
    if (type === GraphType.dashboard) {
      cfg.displayModeBar = false;
    }
    return cfg;
  });

  const [layout] = useState(() => createPlotlyLayout(props));

  const onResize = useCallback(() => {
    if (!state.plotlyRef?.el?._fullData?.length)
      return;
    if (type === GraphType.dashboard && props.interval === GRAPH_INTERVAL.day)
      if (state.plotlyRef.el._fullLayout.width < 250)
        layout.xaxis.tickformat = "%H";
      else
        layout.xaxis.tickformat = null;
    if (!state.plotlyRef?.resizeHandler) return;
    state.plotlyRef?.resizeHandler();
  }, [state.plotlyRef]);
  const { ref } = useResizeDetector({ onResize, skipOnMount: true });

  const postTimeserie = (request: TimeseriesRequestDTO) => {
    request.path = searchParams.get("item_id");
    return postApi<TimeseriesResponseDTO, TimeseriesRequestDTO>(API_URL.METRICS + '/data/timeseries', request,
      {
        signal: aborter.current.signal, params: {
          parent: box.parent?.subsystem,
          top: state.loadedItems,
          order: state.order,
          fixed: state.uuids,
          path: window.location.pathname
        }
      });
  };

  useEffect(() => {
    aborter.current = new AbortController();
    return () => {
      aborter.current.abort();
    }
  }, []);

  useEffect(() => {
    if (!zooming) {
      if (props.state?.additionalYaxis)
        confirmAdditionalYaxisMetric(props.state.additionalYaxis, true);
      if (props.state?.startSec && props.start != props.state?.startSec || props.state?.endSec && props.end != props.state?.endSec)
        zoom(props.state.startSec * 1000, props.state.endSec * 1000);
      else if (props.data) {
        requestData(props.state.startSec, props.state.endSec);
      } else {
        void init();
      }
    }
  }, [props.start, props.end, props.data]);

  useEffect(() => {
    if (regroupedProps?.zoomRange) {
      if (state.startSec === regroupedProps.zoomRange.start && state.endSec === regroupedProps.zoomRange.end) return;
      const start = regroupedProps.zoomRange.start * 1000;
      const end = regroupedProps.zoomRange.end * 1000;
      layout.xaxis.range = [dayjs.tz(start).format(X_FORMAT), dayjs.tz(end).format(X_FORMAT)];
      zoom(start, end);
    } else if (zooming) {
      clearZoom();
    }
  }, [regroupedProps?.zoomRange]);

  function isPeakMetricAllowed() {
    return box.peakMetric && props.interval != 'day';
  }

  function openFullscreen() {
    setPropsFS({
      ...props,
      box: { ...box, graphLabel: getFullScreenTitle(props, box.graphLabel || props.title) },
      type: GraphType.modal,
      title: props.title,
      data: {
        data: JSON.parse(JSON.stringify(data)), tableData: tableData, max: graphUtil.maxValue,
        zoomTitle: getFullScreenTitle(props, (layout.title as { text: string })?.text)
      },
      state: JSON.parse(JSON.stringify({ ...state, plotlyRef: null }))
    });
    setModalFullscreenVisible(true);
  }

  function getFileName() {
    return props.filename ? props.filename
      : `${GLOB.selectedType || GLOB.selectedClass}_${GLOB.selectedItem.titleText}_${props.common?.tabName}_${props.title}`.replaceAll(' ', '-').replaceAll('/', '');
  }

  function assignTickFormat(tblData: KTM[], plotData?: Partial<PlotData>[]) {
    return GraphPlotUtil.assignTickFormat(tblData, plotData || data, props, layout, graphUtil.maxValue);
  }

  function getMaxYrange(metrics: Metric[], plotData: Partial<PlotData>[]) {
    let minY = metrics.map(m => m.minY).reduce((prev, cur) => prev < cur ? cur : prev);
    if (minY) {
      let maxY = minY;
      for (const pd of plotData) {
        maxY = Math.max(minY, ...pd.y as number[]);
      }
      return [0, maxY + 1];
    }
  }

  function updateData(responses: AxiosResponse<TimeseriesResponseDTO>[]) {

    let y2: ReturnType<typeof graphUtil.updatePlotData> = { unitSize: '', plotData: [], tableData: [] };
    if (state.additionalYaxis) {
      const metrics = [state.additionalYaxis as Metric];
      y2 = graphUtil.updatePlotData(responses.pop().data.data, data, metrics);
      y2.plotData[0].yaxis = 'y2';
      layout.yaxis2.range = getMaxYrange(metrics, y2.plotData);
    }
    const updated = graphUtil.updatePlotData(responses[0].data.data, data);

    layout.yaxis.range = getMaxYrange(box.metrics, updated.plotData);

    assignTickFormat(updated.tableData, updated.plotData);
    state.unitSize = updated.unitSize;
    setData([...updated.plotData, ...y2.plotData]);
    setTableData([...updated.tableData, ...y2.tableData]);
  }

  async function init() {
    if (!props.start || !props.end) {
      return;
    }

    return requestData(props.start, props.end);
  }

  function requestData(startSec: number, endSec: number) {
    state.startSec = startSec;
    state.endSec = endSec;
    setLoading(true);
    const uuids = box.uuids;
    const requests: Promise<AxiosResponse<TimeseriesResponseDTO>>[] = [];

    const request = new TimeseriesRequestDTO();
    request.start = startSec;
    request.end = endSec;
    request.uuid = uuids;
    request.metrics = box.metrics.map(m => m.metric);
    if (state.peakMetric) request.metrics.push(box.peakMetric.metric)

    requests.push(postTimeserie(request));

    if (state.additionalYaxis) {
      const request = new TimeseriesRequestDTO();
      request.start = startSec;
      request.end = endSec;
      request.uuid = uuids;
      request.metrics = [state.additionalYaxis.metric];

      requests.push(postTimeserie(request));
    }

    if (!uuids || uuids.length < 1) {
      console.warn('No UUID to plot!');
    }

    return Promise.all(requests).then(
      (responses) => {
        return updateData(responses);
      },
      (reason) => {
        if (axios.isCancel(reason)) return;
        Log.error('Failed to get graph data!', reason);
      }
    ).finally(() => {
      setLoading(false);
      props.onLoadFinished?.();
    });
  }

  function onRelayout(event: Readonly<PlotRelayoutEvent>) {
    if (event.autosize) return;

    if (event.hovermode) {
      const mode = event.hovermode;
      layout.hovermode = state.hoverMode = mode;
      data.forEach(pd => graphUtil.assignHovertemplate(pd));
      setData([...data]);
    }
    else if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      const start = Date.parse(event['xaxis.range[0]'] + '') - DateUtil.OFFSET;
      const end = Date.parse(event['xaxis.range[1]'] + '') - DateUtil.OFFSET;
      zoom(start, end);
    } else if (!Object.keys(event).length || event['xaxis.autorange']) {
      clearZoom();
    }
  }

  function zoom(startMs: number, endMs: number) {
    setZooming(true);
    const minZoomIntervalMs = box.minInterval ? box.minInterval * 60 * 1000 : MIN_ZOOM_INTERVAL;
    if (endMs - startMs <= minZoomIntervalMs) {
      startMs = endMs - minZoomIntervalMs;
      layout.xaxis.range = [dayjs.tz(startMs).format(X_FORMAT), dayjs.tz(endMs).format(X_FORMAT)];
      layout.dragmode = 'pan';
      if (!minZoomNotified) {
        minZoomNotified = true;
        Log.info(`Minimum zoom range for this graph is ${dayjs.duration(minZoomIntervalMs).asMinutes()} minutes.`);
      }
    } else {
      layout.dragmode = 'zoom';
    }
    (layout.title as { text: string }).text = `${box.graphLabel || props.title || ''}: ${DateUtil.getShortDateTime(startMs)} - ${DateUtil.getShortDateTime(endMs)}`;
    const startSec = startMs / 1000;
    const endSec = endMs / 1000;
    type !== GraphType.modal && regroupedProps.setZoomRange?.({ end: endSec, start: startSec });
    void requestData(startSec, endSec);
  }

  function clearZoom() {
    if (!GraphProps.isZoomAllowed(box)) return;
    if (layout.xaxis.fixedrange) {
      layout.xaxis.fixedrange = false;
      layout.xaxis.autorange = true;
    }
    layout.dragmode = 'zoom';
    (layout.title as { text: string }).text = box.graphLabel || props.title;
    setZooming(false);
    type !== GraphType.modal && regroupedProps.setZoomRange?.(null);
    void init();
  }

  async function loadOtherItems(itemCount: number, order: TimeseriesTopOrder) {
    state.loadedItems = itemCount;
    state.order = order;
    return requestData(state.startSec, state.endSec).then(() => setModalOtherOpen(false));
  }

  function confirmAdditionalYaxisMetric(metric: IMetric, layoutOnly = false) {
    state.additionalYaxis = metric;
    if (metric) {
      const ya = createYaxis([metric as Metric]);
      ya.overlaying = 'y';
      ya.side = 'right';
      ya.griddash = 'dot';
      layout.yaxis2 = ya;
      layout.margin.r = 40;
    } else {
      delete layout.yaxis2;
      layout.margin.r = 10;
    }
    if (layoutOnly) return;
    return requestData(state.startSec, state.endSec).then(() => {
      setModalAxisOpen(false);
    });
  }

  return (
    <>
      {props.interval && type === GraphType.regular && props.common.dashboardGraphs &&
        <GraphDashboardSelect timestamp={dashSelectTimestamp} common={props.common} interval={props.interval} />}

      <div ref={ref} className="xm-graph" onClick={onClick}>
        {loading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          null
        )}

        <TopItemsModal open={modalOtherOpen} order={state.order} count={state.loadedItems} maxCount={box.uuids.length}
          onConfirm={loadOtherItems} onCancel={() => setModalOtherOpen(false)} />

        <YaxisModal open={modalAxisOpen} availableMetrics={props.common?.availableMetrics} metric={state.additionalYaxis}
          onCancel={() => setModalAxisOpen(false)} onConfirm={confirmAdditionalYaxisMetric} />

        {
          type !== GraphType.dashboard &&
          <GraphBtns dropdownItems={dropdownItems} layout={layout} refresh={() => setData(prev => [...prev])}
            modebarBtns={config.modeBarButtons as [][]} />
        }

        {plotVisible ? <Plot ref={r => state.plotlyRef = r as PlotCmp}
          className={`xm-plotly js-plotly-plot ${props.common?.dashboardGraphs?.some(fg => fg.interval === props.interval) ? 'xm-graph-in-dash' : ''}`}
          data={data} layout={{ ...layout }} onRelayout={onRelayout}
          useResizeHandler={true} config={config} />
          : <div style={{ height: layout.height }}><Spinner /></div>}

        {type === GraphType.dashboard ? null : <GraphTable graphPlotData={graphUtil} state={state} graphProps={props} data={data} setData={setData}
          tableData={tableData} setTableData={setTableData} assignTickFormat={assignTickFormat} setPlotVisible={setPlotVisible} />}
      </div>

      {type !== GraphType.modal &&
        <Modal centered open={modalFullscreenVisible} onCancel={() => setModalFullscreenVisible(false)} destroyOnClose={true} closable={false} footer={null} width="90%">
          <Graph {...propsFS} />
        </Modal>}
    </>
  );
};
