import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { CreateDashboardGraphDTO, CreateResponseDTO, DashboardGraph, FindGraph, UpdateDashboardGraphDTO } from "@dto/dashboard.dto";
import { MenuItem, MenuResponseDTO } from "@dto/menuResponse.dto";
import { DashboardBox, GraphInterval, PageResponseDTO } from "@dto/pageResponse.dto";
import { DashboardContextMenu, MenuDataNode } from "@parts/dashboard/DashboardContextMenu";
import { Button, Divider, Modal, Space, TreeSelect } from "antd";
import axios from "axios";
import { ChangeEventExtra } from "rc-tree-select/lib/interface";
import React, { ReactNode } from "react";
import { FaPlus, FaTimes } from 'react-icons/fa';
import { postApi, putApi } from "src/util/apiCalls";
import { API_URL } from "../../../data/Api";
import { MenuNode } from "../../../model/MenuNode";
import { Log } from '../../../service/Log';
import { GLOB } from "../../../util/Glob";
import { AsyncButton } from "../../asyncButton/AsyncButton";
import { CommonGraphProps } from "../GraphTypes";

interface GraphDashboardSelectProps {
  interval: GraphInterval;
  common: CommonGraphProps;
  timestamp: number;
}

interface GraphDashboardSelectState {
  selects: DashboardSelect[];
  options: MenuDataNode[];
  modalOpen: boolean;
  dashboardGraphs: FindGraph[];
}

class DashboardSelect {
  value: string;
  selected: MenuDataNode;
  dashId: string;
  open: boolean;
  id: string;
  key: number;
  expandedKeys: React.Key[];
}

export default class GraphDashboardSelect extends React.Component<GraphDashboardSelectProps, GraphDashboardSelectState> {

  private readonly contextMenu: DashboardContextMenu;
  private selectCount = 0;
  private operations: Record<string, () => Promise<unknown>> = {};

  constructor(props: GraphDashboardSelectProps) {
    super(props);
    this.state = {
      selects: [],
      options: [],
      modalOpen: false,
      dashboardGraphs: []
    };
    this.contextMenu = new DashboardContextMenu(() => this.state, this.setState.bind(this), (item) => item.dashboard,
      item => item.group, this.onDelete.bind(this), null, true);
  }

  componentDidMount(): void {
    const dashMenu = GLOB.menuService.getDashMenu().children.map(mn => {
      if (mn.key === MenuNode.GROUPS_DIVIDER_KEY) {
        return { ...mn, itemId: mn.key, disabled: true, title: <div>------------------------- Group Dashboards ------------------------</div> };
      }
      return mn;
    });
    this.setState({
      dashboardGraphs: [...(this.props.common?.dashboardGraphs || [])],
      options: this.menuNodeToDataNode([{
        title: 'Dashboard', itemId: DashboardContextMenu.ROOT, key: DashboardContextMenu.ROOT,
        children: dashMenu.filter(mn => !MenuNode.DASH_EXCLUDED_ITEM_IDS.includes(mn.itemId))
      }])
    });
  }

  componentDidUpdate(prevProps: Readonly<GraphDashboardSelectProps>, prevState: Readonly<GraphDashboardSelectState>, snapshot?): void {
    if (this.props.timestamp && prevProps.timestamp !== this.props.timestamp) {
      this.operations = {};
      const existing = this.props.common?.dashboardGraphs?.filter(fg => fg.interval === this.props.interval);
      if (existing?.length)
        this.setState({ selects: existing.map(fg => this.createSelect(fg)) });
      else {
        this.setState({ selects: [this.createSelect()] });
      }
      this.setState({ modalOpen: true });
    }
  }

  private onDelete(item: MenuDataNode) {
    const open = this.state.selects.find(ds => ds.open);
    if (open?.selected && open.selected.value === item.value) {
      // deleted by cascade?
      //axios.delete<AxiosResponse<BasicResponseDTO>>(API+'')
      open.selected = null;
      open.value = null;
      open.id = null;
    }
  }

  private createSelect(fg?: FindGraph): DashboardSelect {
    return {
      open: false, expandedKeys: [], value: fg ? `${fg.dashboard.label} / ${fg.dashboard_group.label}` : null,
      selected: fg ? {
        key: fg.dashboard_group.dashboard_group_id, value: fg.dashboard_group.dashboard_group_id, itemId: fg.dashboard_group.dashboard_group_id,
        titleText: fg.dashboard_group.label, isLeaf: true, dashboard: false, group: true
      } : null, key: this.selectCount++, id: fg?.dashboard_graph_id, dashId: fg?.dashboard.dashboard_id
    };
  }

  private setOpen(open: boolean, dc: DashboardSelect) {
    dc.open = open;
    this.setState(prev => ({ selects: prev.selects.map(c => c === dc ? { ...c } : { ...c, open: false }) }));
  }

  private menuItemToDataNode(item: MenuItem): MenuDataNode {
    // TODO: remove ignore when the problem with menuResponse.dto is resolved (MenuItem.title)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    const mdn: MenuDataNode = { ...item, key: item.itemId, label: null, titleText: item.title, value: item.itemId, isLeaf: false, dashboard: item.isLeaf, children: null };
    mdn.title = this.contextMenu.createLabel(mdn);
    return mdn;
  }

  private menuNodeToDataNode(nodes: MenuNode[]): MenuDataNode[] {
    if (!nodes?.length) {
      return [];
    }
    return nodes.map(mn => {
      const mdn: MenuDataNode = {
        ...mn, title: mn.title as ReactNode, label: null, key: mn.itemId, value: mn.itemId, titleText: mn.title as string, isLeaf: false,
        children: this.menuNodeToDataNode(mn.children), dashboard: mn.isLeaf
      };
      if (mdn.divider) {
        mdn.children = null;
        mdn.isLeaf = true;
      }
      mdn.title = this.contextMenu.createLabel(mdn);
      return mdn;
    });
  }

  private refreshDashOpts(item: MenuDataNode) {
    const result = this.refreshRekur(item, this.state.options);
    if (result) this.setState({ options: result });
  }

  private refreshRekur(item: MenuDataNode, nodes: MenuDataNode[]) {
    if (!item || !nodes)
      return;
    for (const node of nodes) {
      if (DashboardContextMenu.equals(item, node)) {
        if (item.children)
          item.children = [...item.children];
        return nodes.map(mdn => DashboardContextMenu.equals(item, mdn) ? { ...item } : mdn);
      }
      const result = this.refreshRekur(item, node.children);
      if (result) {
        node.children = result;
        return [...nodes];
      }
    }
  }

  private removeGraph(dc: DashboardSelect, dashboardGraphs = this.props.common.dashboardGraphs) {
    const idx = dashboardGraphs.findIndex(fg => fg.dashboard_graph_id === dc.id);
    if (idx >= 0)
      dashboardGraphs.splice(idx, 1);
    this.setState(prev => ({ selects: prev.selects.filter(c => c !== dc).map(c => ({ ...c, open: false })) }));
  }

  private removeSelect(ds: DashboardSelect) {
    if (ds.id) {
      // Modal.confirm({
      //   title: 'Confirm remove',
      //   autoFocusButton: null,
      //   zIndex: 2000,
      //   content: <span>Are you sure to remove graph from <strong>{dc.selected.titleText}</strong>?</span>,
      //   okText: 'Remove',
      //   onOk: () => {
      //     return axios.delete<BasicResponseDTO>(API_URL.DASHBOARD_GRAPH + '/' + dc.id).then(response => {
      //       this.removeGraph(dc);
      //     }, reason => Log.error('Failed to remove graph from dashboard!', reason));
      //   }
      // });

      this.removeGraph(ds, this.state.dashboardGraphs);
      this.operations[ds.key] = () => axios.delete<BasicResponseDTO>(API_URL.DASHBOARD_GRAPH + '/' + ds.id).then(response => {
        this.removeGraph(ds);
      }, reason => {
        Log.error('Failed to remove graph from dashboard!', reason);
      });

    } else {
      this.removeGraph(ds, this.state.dashboardGraphs);
      delete this.operations[ds.key];
    }
  }

  private addCascader() {
    this.setState({ selects: [...this.state.selects.map(c => ({ ...c, open: false })), this.createSelect()] });
  }

  private onLoadData(dataNode: MenuDataNode) {
    if (dataNode.group || dataNode.itemId === DashboardContextMenu.ROOT) {
      return Promise.resolve();
    }
    dataNode.loading = true;
    if (dataNode.dashboard) {
      return axios.get<PageResponseDTO>(dataNode.url).then(response => {
        const box = response.data.data.tabs[0].content[0] as DashboardBox;
        dataNode.children = box.groups.map(group => {
          const item: MenuDataNode = { label: null, key: group.dashboard_group_id, value: group.dashboard_group_id, titleText: group.label, itemId: group.dashboard_group_id, group: true, isLeaf: true };
          item.title = this.contextMenu.createLabel(item);
          return item;
        });
        if (!dataNode.children?.length) {
          dataNode.children = [{
            disabled: true, label: 'No groups yet', key: dataNode.key + DashboardContextMenu.NO_GROUP, value: dataNode.value + DashboardContextMenu.NO_GROUP,
            itemId: DashboardContextMenu.NO_GROUP, group: true, isLeaf: true
          }];
        }
        this.refreshDashOpts(dataNode);
      }, reason => {
        Log.error('Failed to get dashboard!', reason);
      }).finally(() => dataNode.loading = false);
    } else if (dataNode.group_id) {
      return Promise.resolve([]);
    } else {
      return axios.get<MenuResponseDTO>(dataNode.url).then(response => {
        dataNode.children = response.data.data.map(mi => this.menuItemToDataNode(mi));
        this.refreshDashOpts(dataNode);
      }, reason => {
        Log.error('Failed to get dashboard folder!', reason);
      }).finally(() => dataNode.loading = false);
    }
  }

  private findRekur(value: string, options: MenuDataNode[]): MenuDataNode[] {
    if (!value || !options) return;
    for (const node of options) {
      if (node.value === value) return [node];
      const result = this.findRekur(value, node.children);
      if (result) {
        result.push(node);
        return result;
      }
    }
  }

  private updateGraph(ds: DashboardSelect, dash: MenuDataNode, group: MenuDataNode, dashboardGraphs = this.props.common.dashboardGraphs) {
    const foundGraph = dashboardGraphs.find(fg => fg.dashboard_graph_id === ds.id);
    if (!foundGraph) {
      const msg = 'Update graph in dashboard failed, ID lost: ' + ds.id;
      Log.error(msg);
      return;
    }
    foundGraph.dashboard_graph_id = ds.id;
    foundGraph.interval = this.props.interval;
    foundGraph.dashboard_group = { dashboard_group_id: group.itemId, label: group.titleText };
    foundGraph.dashboard = { dashboard_id: dash.itemId, label: dash.titleText };
  }

  private addGraph(ds: DashboardSelect, dash: MenuDataNode, group: MenuDataNode, dashboardGraphs = this.props.common.dashboardGraphs) {
    dashboardGraphs.push({
      dashboard_graph_id: ds.id, interval: this.props.interval,
      dashboard_group: { dashboard_group_id: group.itemId, label: group.titleText },
      dashboard: { dashboard_id: dash.itemId, label: dash.titleText }
    });
  }

  private onChange(value: string, ds: DashboardSelect) {
    const found = this.findRekur(value, this.state.options);
    if (found?.[0].isLeaf) {
      const group = found[0];
      const dash = found[1];
      if (this.state.dashboardGraphs.some(fg => fg.interval === this.props.interval
        && fg.dashboard.dashboard_id === dash.itemId && fg.dashboard_group.dashboard_group_id === group.itemId)) {
        Log.warn('Graph is already present in this dashboard location.');
        return;
      }
      if (ds.id) {
        this.updateGraph(ds, dash, group, this.state.dashboardGraphs);
        this.operations[ds.key] = () => putApi<BasicResponseDTO, UpdateDashboardGraphDTO>(API_URL.DASHBOARD_GRAPH + '/' + ds.id,
          { dashboard_group_id: group.itemId, x: DashboardGraph.DEFAULT_COORDINATE, y: DashboardGraph.DEFAULT_COORDINATE }).then(response => {
            this.updateGraph(ds, dash, group);
          }, reason => {
            Log.error('Failed to update graph in group ' + group.titleText, reason);
          });

      } else {
        this.addGraph(ds, dash, group, this.state.dashboardGraphs);
        this.operations[ds.key] = () => postApi<CreateResponseDTO, CreateDashboardGraphDTO>(API_URL.DASHBOARD_GRAPH,
          { dashboard_group_id: group.itemId, interval: this.props.interval, tab: this.props.common.tabName, url: this.props.common.dashUrl }).then(response => {
            ds.id = response.data.data.id;
            this.addGraph(ds, dash, group);
          }, reason => Log.error('Failed to add graph to dashboard group ' + group.titleText, reason));
      }
      ds.selected = group;
      ds.value = found.reverse().map(mdn => mdn.titleText).join(' / ');
      this.setOpen(false, ds);
    }
  }

  private dropdownRender(menu: React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>) {
    return <div>
      {menu}
      <Divider style={{ margin: '12px 0' }} />
      <div style={{ margin: '0 0.5em' }}>Right click to create folder, dashboard or group.</div>
    </div>;
  }

  render() {
    return <Modal open={this.state.modalOpen} destroyOnClose={true} closable={false} footer={<div>
      <Button title="Add another dashboard select" onClick={() => this.addCascader()}
        style={{ float: 'left', display: 'inline-flex', alignItems: 'baseline' }} icon={<FaPlus />}>
        &nbsp;Add entry
      </Button>
      <Button onClick={() => {
        this.setState({ modalOpen: false });
      }}>Cancel</Button>
      <AsyncButton type="primary" disabled={this.state.selects.some(ds => !ds.selected)}
        onClickAsync={() => {
          return Promise.all(Object.values(this.operations).map(o => o())).then(() => {
            setTimeout(() => {
              this.props.common.setDashboardGraphs([...this.props.common.dashboardGraphs]);
              this.setState({ modalOpen: false, dashboardGraphs: [...this.props.common.dashboardGraphs] });
            });
          }, reason => console.log('Failed to update graph in dashboard!', reason));
        }}>Save</AsyncButton>
    </div>}>
      <Space direction="vertical" style={{ width: '100%', marginTop: '-6px' }}>
        <h3>Show graph in dashboards:</h3>
        {this.state.selects.map((ds, index) => <div key={ds.key} style={{ display: 'flex' }}>
          <TreeSelect
            style={{ width: '100%' }}
            value={ds.value}
            popupClassName="xm-dashboard-select"
            virtual={false}
            dropdownRender={this.dropdownRender}
            placeholder="Select dashboard"
            onChange={(value: string, labelList: React.ReactNode[], extra: ChangeEventExtra) => this.onChange(value, ds)}
            loadData={dataNode => this.onLoadData(dataNode as MenuDataNode)}
            treeData={this.state.options}
            open={this.state.selects.find(c => c.key === ds.key)?.open}
            onClick={ev => (ev.target as Element).closest('.ant-tree-select') ? this.setOpen(!ds.open, ds) : null}
            onSelect={(value, option) => {
              if (ds.expandedKeys.includes(option.key)) {
                ds.expandedKeys = ds.expandedKeys.filter(k => k !== option.key);
              } else {
                ds.expandedKeys = [...ds.expandedKeys, option.key];
              }
              this.setState(prev => ({ selects: [...prev.selects] }));
            }}
            onTreeExpand={exKeys => {
              ds.expandedKeys = exKeys;
              this.setState(prev => ({ selects: [...prev.selects] }));
            }}
            treeExpandedKeys={this.state.selects[index].expandedKeys}
          />
          <Button type="text" title="Remove from this dashboard" icon={<FaTimes />} onClick={() => this.removeSelect(ds)}></Button>
        </div>)}
      </Space>
    </Modal>
  }
}