import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export abstract class DateUtil {

  private static readonly OFFSET_BROWSER = dayjs().utcOffset() * 60 * 1000;
  private static _offset = 0;
  /**
   * Target timezone offset in milliseconds minus client browser timezone offset
   */
  static get OFFSET() {
    return DateUtil._offset;
  }

  static setOffset(timezone: string) {
    dayjs.tz.setDefault(timezone);
    const offsetTarget = dayjs().tz().utcOffset() * 60 * 1000;
    DateUtil._offset = offsetTarget - DateUtil.OFFSET_BROWSER;
  }

  /**
   * Returns date formatted as "YYYY-MM-DD HH:mm:ss" string
   * @param date date to format
   * @param seconds false to skip seconds
   * @returns
   */
  static getDateISO(date?: string | number | Date | dayjs.Dayjs, seconds = true) {
    return dayjs.tz(date).format(`YYYY-MM-DD HH:mm${seconds ? ':ss' : ''}`);
  }

  /**
   * Returns date formatted as 'DD-MMM HH:mm'
   * @param date date to format
   * @returns
   */
  static getShortDateTime(date?: string | number | Date | dayjs.Dayjs) {
    return dayjs.tz(date).format('DD-MMM HH:mm');
  }

  /**
   * Returns date formatted as 'MMM DD, YYYY'
   * @param date date to format
   * @returns
   */
  static getShortDate(date?: string | number | Date | dayjs.Dayjs) {
    return dayjs.tz(date).format('MMM DD, YYYY');
  }

  /**
   * Returns unix time in seconds formatted as 'MMM DD YYYY HH:mm:ss' string
   * @param unixTimeSec unix time in seconds
   * @returns
   */
  static convertUnixToHuman(unixTimeSec: number) {
    return dayjs.tz(unixTimeSec * 1000).format('MMM DD YYYY HH:mm:ss');
  }

  /**
  * Get daily graph start/end from now in seconds
  * @returns
  */
  static initShort() {
    const endShort = Date.now() / 1000;
    const startDay = new Date((endShort - 60 * 60 * 24 * 1) * 1000).getTime() / 1000;
    return { endShort, startDay };
  }

  /**
   * Get week, month, year graph start/end from now in seconds
   * @returns
   */
  static initLong() {
    const endLong = Date.now() / 1000;
    const startWeek = new Date((endLong - 60 * 60 * 24 * 7) * 1000).getTime() / 1000;
    const startMonth = new Date((endLong - 60 * 60 * 24 * 30) * 1000).getTime() / 1000;
    const startYear = new Date((endLong - 60 * 60 * 24 * 365) * 1000).getTime() / 1000;
    return { endLong, startWeek, startMonth, startYear };
  }
}