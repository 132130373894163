export const ClassEnum = {
  SERVER: 'virtualization',
  STORAGE: 'storage',
  SAN: 'san',
  LAN: 'lan',
  DATABASE: 'database',
  CONTAINER: 'container',
  CLOUD: 'cloud'
} as const;

export const LPAR = [ClassEnum.SERVER, ClassEnum.DATABASE, ClassEnum.CONTAINER, ClassEnum.CLOUD] as const;
export const STOR = [ClassEnum.STORAGE, ClassEnum.SAN, ClassEnum.LAN] as const;

export type ClassType = typeof ClassEnum[keyof typeof ClassEnum];

export function isClassLPAR(cls: string) {
  return (LPAR as readonly string[]).includes(cls)
}

export function isClassSTOR(cls: string) {
  return (STOR as readonly string[]).includes(cls);
}

export function isClassNetwork(cls: string) {
  return cls === ClassEnum.SAN || cls === ClassEnum.LAN;
}